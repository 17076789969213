import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCard from 'src/components/ui/videos/video-card';
import { ROUTES } from 'src/config/route';
import { VideoForm } from 'src/lib/form-interfaces';
import {
	CmsDeleteVenueVideo,
	CmsDeleteVenueVideoVariables,
} from 'src/lib/gql/generated/CmsDeleteVenueVideo';
import {
	CmsGetVenueVideo,
	CmsGetVenueVideoVariables,
} from 'src/lib/gql/generated/CmsGetVenueVideo';
import {
	CmsUpdateVenueVideo,
	CmsUpdateVenueVideoVariables,
} from 'src/lib/gql/generated/CmsUpdateVenueVideo';
import {
	MutationDeleteVenueVideo,
	MutationUpdateVenueVideo,
} from 'src/lib/gql/mutations';
import { QueryGetVenueVideo } from 'src/lib/gql/queries';

const EditIntroVideo = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenueVideo, CmsGetVenueVideoVariables>(
		QueryGetVenueVideo,
		{
			variables: {
				id: params.id ?? '',
			},
		},
	);

	const [UpdateVenueVideo] = useMutation<
		CmsUpdateVenueVideo,
		CmsUpdateVenueVideoVariables
	>(MutationUpdateVenueVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos',
				pagePrefix: 'Venue Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data: VideoForm) => {
			const rsp = await UpdateVenueVideo({
				variables: {
					id: params.id || '',
					updateVenueVideoInput: {
						name: data.name,
						thumbnailId: data.thumbnailId,
						videoUrl: data.videoUrl,
						weight: data.weight ? parseInt(data.weight.toString()) : null,
						venueId: data.venueId,
						videoTypeId: data.videoTypeId,
						venueVideoHTags: data.tags,
					},
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos',
					notificationType: 'updated',
					pagePrefix: 'Venue Video',
				});

				refetchQuery();
			}
		},
		[qryData?.getVenueVideo?.id],
	);

	const [deleteVideo] = useMutation<
		CmsDeleteVenueVideo,
		CmsDeleteVenueVideoVariables
	>(MutationDeleteVenueVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos',
				pagePrefix: 'Venue Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Venue Video</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				{loading ? (
					<Skeleton active />
				) : (
					<VideoCard
						venueId={qryData?.getVenueVideo?.venue?.id}
						venueVideoName={qryData?.getVenueVideo?.name || ''}
						venueVideoThumbnail={
							qryData?.getVenueVideo?.video?.thumbnail || undefined
						}
						venueVideoUrl={qryData?.getVenueVideo?.video?.url}
						venueVideoWeight={qryData?.getVenueVideo?.weight || 1}
						venueVideoTags={qryData?.getVenueVideo?.venueVideoHTag?.map(
							(vtag, i) => vtag.hTag || '',
						)}
						videoTypeId={qryData?.getVenueVideo?.videoType?.id}
						onSave={updateQry}
						canDelete={true}
						onDelete={async () => {
							if (!confirm('Are you sure you want to delete?')) return;
							const rsp = await deleteVideo({
								variables: {
									id: params.id ?? '',
								},
							});

							if (rsp.data) {
								CustomNotification({
									pageName: 'Videos',
									pagePrefix: 'Video',
									notificationType: 'deleted',
								});

								navigate(`${ROUTES.VIDEOS.path}`);
							}
						}}
					/>
				)}
			</Row>
		</Row>
	);
};

export default EditIntroVideo;
