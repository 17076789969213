import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import IntroCard from 'src/components/ui/intro-video/intro-card';
import { ROUTES } from 'src/config/route';
import { IntroForm } from 'src/lib/form-interfaces';
import {
	CmsCreateIntroVideo as CreateIntroVideoMutation,
	CmsCreateIntroVideoVariables,
} from 'src/lib/gql/generated/CmsCreateIntroVideo';
import { MutationCreateIntroVideo } from 'src/lib/gql/mutations';
import { Media } from 'src/lib/interface';

const CreateIntroVideo = () => {
	const navigate = useNavigate();

	const [videoThumbnailId, setVideoThumbnailId] = useState<Media | undefined>(
		undefined,
	);

	const [CreateIntro] = useMutation<
		CreateIntroVideoMutation,
		CmsCreateIntroVideoVariables
	>(MutationCreateIntroVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Intro Video',
				pagePrefix: 'Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: IntroForm) => {
		const rsp = await CreateIntro({
			variables: {
				dates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
				name: data.name,
				thumbnailId: data.thumbnailId,
				videoUrl: data.videoUrl,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Intro Video',
				pagePrefix: 'Video',
				notificationType: 'created',
			});

			navigate(
				`${ROUTES.EDIT_INTRO_VIDEO.path}/${rsp.data.createIntroVideo?.id}`,
			);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Intro Video</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24} lg={12}>
					{/* <MediaUpload /> */}
				</Col>

				<IntroCard onSave={createQry} />
			</Row>
		</Row>
	);
};

export default CreateIntroVideo;
