import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateSliderMutation,
	CmsCreateSliderMutationVariables,
} from 'src/lib/gql/generated/CmsCreateSliderMutation';
import { CreateSlider as CreateSliderM } from 'src/lib/gql/mutations';
import SliderCard from '../../components/ui/sliders/slider-card';

const CreateSlider = () => {
	const [createSlider] = useMutation<
		CmsCreateSliderMutation,
		CmsCreateSliderMutationVariables
	>(CreateSliderM, {
		onError(error) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const navigate = useNavigate();
	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Slider</Text>
			</Col>

			<SliderCard
				onSave={async (data) => {
					const rsp = await createSlider({
						variables: {
							sliderDates:
								data.dates.map((date) => format(date, 'yyyy-MM-dd')) || [],
							sliderHTags: data.tags || [],
							mediaId: data.mediaId,
							weight: data.position,
							link: data.link,
							mediaType: data.mediaType,
							title: data.name || '',
							videoLink: data.videoLink,
						},
					});
					if (rsp.data) {
						CustomNotification({
							pageName: 'Sliders',
							pagePrefix: 'Slider',
							notificationType: 'created',
						});
						navigate(`${ROUTES.EDIT_SLIDER.path}/${rsp.data.createSlider?.id}`);
					}
				}}
			/>
		</Row>
	);
};

export default CreateSlider;
