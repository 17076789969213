import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCard from 'src/components/ui/videos/video-card';
import { ROUTES } from 'src/config/route';
import { VideoForm } from 'src/lib/form-interfaces';
import {
	CmsCreateVenueVideo,
	CmsCreateVenueVideoVariables,
} from 'src/lib/gql/generated/CmsCreateVenueVideo';
import { MutationCreateVenueVideo } from 'src/lib/gql/mutations';

const CreateVideos = () => {
	const navigate = useNavigate();

	const [CreateVenueVideos] = useMutation<
		CmsCreateVenueVideo,
		CmsCreateVenueVideoVariables
	>(MutationCreateVenueVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Venue Videos',
				pagePrefix: 'Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: VideoForm) => {
		const rsp = await CreateVenueVideos({
			variables: {
				venueVideoInput: {
					name: data.name,
					thumbnailId: data.thumbnailId,
					videoUrl: data.videoUrl,
					venueId: data.venueId,
					videoTypeId: data.videoTypeId,
					weight: data.weight ? parseInt(data.weight.toString()) : null,
					venueVideoHTags: data.tags,
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Venue Video',
				pagePrefix: 'Video',
				notificationType: 'created',
			});

			navigate(`${ROUTES.EDIT_VIDEOS.path}/${rsp.data.createVenueVideo?.id}`);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Venue Video</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				<VideoCard onSave={createQry} />
			</Row>
		</Row>
	);
};

export default CreateVideos;
