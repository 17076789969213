/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdUnitType {
	HALF_PAGE = 'HALF_PAGE',
	MAIN_LEADER_BOARD = 'MAIN_LEADER_BOARD',
	SUB_LEADER_BOARD = 'SUB_LEADER_BOARD',
}

export enum Gender {
	FEMALE = 'FEMALE',
	MALE = 'MALE',
	OTHER = 'OTHER',
}

export enum ImageOrEmbedded {
	EMBEDDED = 'EMBEDDED',
	IMAGE = 'IMAGE',
}

export enum MediaType {
	EMBEDDED = 'EMBEDDED',
	GIF = 'GIF',
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
}

export enum OccurrenceTime {
	ALL = 'ALL',
	DAY = 'DAY',
	NIGHT = 'NIGHT',
}

export enum Role {
	ADMIN = 'ADMIN',
	PENDING_USER = 'PENDING_USER',
	PHOTOGRAPHER = 'PHOTOGRAPHER',
	PHOTOGRAPHER_PENDING = 'PHOTOGRAPHER_PENDING',
	SUPER_ADMIN = 'SUPER_ADMIN',
	USER = 'USER',
}

export enum VenueStatus {
	CLOSED = 'CLOSED',
	CLOSED_RENOVATION = 'CLOSED_RENOVATION',
	CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
	CLOSED_TODAY = 'CLOSED_TODAY',
	OPEN = 'OPEN',
}

export interface ArticleJumpToInput {
	articleId?: string | null;
	jumpToLink: string;
	jumpToText: string;
}

export interface ArticleTypeInput {
	name: string;
	weight?: number | null;
	slug: string;
	description?: string | null;
	meta?: string | null;
}

export interface EventDateInput {
	photographer: boolean;
	eventDates: any[];
	featured: boolean;
}

export interface EventInput {
	name: string;
	description: string;
	offers?: string | null;
	ctaLink?: string | null;
	ctaNumber?: string | null;
	posterId?: string | null;
	videoUrl?: string | null;
	eventParentInput: EventParentInput;
	eventDateInput: EventDateInput;
}

export interface EventParentInput {
	parentName: string;
	meta?: string | null;
	eventTypeIds: string[];
	venueId: string;
	hTag: string;
	slug: string;
	occurrenceTime: OccurrenceTime;
	genreIds: string[];
}

export interface EventPhotoInput {
	eventDateId: string;
	mediaId: string[];
}

export interface EventPhotographersInput {
	photographerIds: string[];
	eventDateId: string;
	adminComment?: string | null;
}

export interface FeaturedListInput {
	hTag: string;
	weight?: number | null;
	articleId: string;
}

export interface GenreInput {
	name: string;
	enabled?: boolean | null;
}

export interface OfferTypeInput {
	name: string;
	enabled?: boolean | null;
}

export interface RegionInput {
	brandName?: string | null;
	tagLine?: string | null;
	address?: string | null;
	email?: string | null;
	phone?: string | null;
	terms?: string | null;
	privacy?: string | null;
	socialInstagram?: string | null;
	socialFb?: string | null;
	socialTwitter?: string | null;
}

export interface UpdateArticleJumpToInput {
	articleId?: string | null;
	jumpToLink?: string | null;
	jumpToText?: string | null;
}

export interface UpdateArticleTypeInput {
	name?: string | null;
	weight?: number | null;
	enabled?: boolean | null;
	slug?: string | null;
	description?: string | null;
	meta?: string | null;
}

export interface UpdateEventInput {
	name: string;
	description: string;
	offers?: string | null;
	ctaLink?: string | null;
	ctaNumber?: string | null;
	posterId?: string | null;
	videoUrl?: string | null;
	applyChangesTo?: string[] | null;
	additionalDates?: any[] | null;
}

export interface UpdateEventParentInput {
	parentName?: string | null;
	meta?: string | null;
	eventTypeIds?: string[] | null;
	venueId?: string | null;
	hTag?: string | null;
	slug?: string | null;
	occurrenceTime?: OccurrenceTime | null;
	genreIds?: string[] | null;
}

export interface UpdateEventPhotosInput {
	eventPhotoId: string[];
	approved?: boolean | null;
	changeOrder?: boolean | null;
}

export interface UpdateVenueVideoInput {
	name?: string | null;
	venueId?: string | null;
	videoUrl?: string | null;
	thumbnailId?: string | null;
	videoTypeId?: string | null;
	weight?: number | null;
	enabled?: boolean | null;
	venueVideoHTags?: string[] | null;
}

export interface UpdateVideoTypeInput {
	name?: string | null;
	weight?: number | null;
	enabled?: boolean | null;
}

export interface VenueVideoInput {
	name: string;
	venueId: string;
	thumbnailId: string;
	videoTypeId: string;
	videoUrl: string;
	weight?: number | null;
	venueVideoHTags: string[];
}

export interface VideoTypeInput {
	name: string;
	weight?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
