import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
} from 'react-router-dom';
import { CmsMe } from 'src/lib/gql/generated/CmsMe';
import { QueryMe } from 'src/lib/gql/queries';
import { ROUTES, genPath } from './config/route';
import MainLayout from './layout/layout';
import { Role } from './lib/gql/generated/globalTypes';
import BestPlaces from './pages/best-places';
import CreateBestPlaces from './pages/best-places/create-best-places';
import EditBestPlaces from './pages/best-places/edit-best-places';
import DiscoverCity from './pages/discover-city';
import CreateCity from './pages/discover-city/create-city';
import EditCity from './pages/discover-city/edit-city';
import EventsActivation from './pages/event-activation';
import EventGenre from './pages/event-genre';
import EventPhotographer from './pages/event-photographer';
import Events from './pages/events';
import EditActivateEvent from './pages/events/activate-event';
import ReportVenue from './pages/venues/report-venues';
import CreateEvent from './pages/events/create-event';
import EditEvent from './pages/events/edit-event';
import IntroVideo from './pages/intro-video';
import CreateIntroVideo from './pages/intro-video/create-intro-video';
import EditIntroVideo from './pages/intro-video/edit-intro-video';
import LoginPage from './pages/login';
import News from './pages/news';
import NewsCategory from './pages/news-category';
import NewsComments from './pages/news/comments';
import CreateNewsArticle from './pages/news/create-article';
import CreateArticleCategory from './pages/news/create-article-category';
import EditArticle from './pages/news/edit-article';
import EditArticleCategory from './pages/news/edit-article-category';
import OfferCategory from './pages/offer-category';
import Offers from './pages/offers';
import CreateOffers from './pages/offers/create-offers';
import EditOffers from './pages/offers/edit-offers';
import PhotographerEventSelfAssign from './pages/photographer-event-selfassign';
import PhotographerHomeView from './pages/photographer/photographer-home-view';
import PhotographerPhotosView from './pages/photographer/photographer-photos-view';
// import PhotographerProfile from './pages/photographer/photographer-profile';
import FeaturedList from './pages/featured-list';
import OffersActivation from './pages/offers/offers-activation';
import Photographers from './pages/photographers';
import PhotographerRequests from './pages/photographers-requests';
import Photos from './pages/photos';
import EditPhotos from './pages/photos/edit-photos';
import PopupAd from './pages/popup-ad';
import CreatePopupAd from './pages/popup-ad/create-popup-ad';
import EditPopupAd from './pages/popup-ad/edit-popup-ad';
import RegionSettings from './pages/region-settings';
import Sliders from './pages/sliders';
import CreateSlider from './pages/sliders/create-slider';
import EditSlider from './pages/sliders/edit-slider';
import Stories from './pages/stories';
import CreateStory from './pages/stories/create-story';
import EditStory from './pages/stories/edit-story';
import ThingsToDo from './pages/things-to-do';
import CreateThingsToDo from './pages/things-to-do/create-things-to-do';
import EditThingsToDo from './pages/things-to-do/edit-things-to-do';
import Users from './pages/users';
import Venues from './pages/venues';
import CreateVenues from './pages/venues/create-venues';
import EditVenues from './pages/venues/edit-venues';
import Videos from './pages/videos';
import VideosCategory from './pages/videos-category';
import CreateVideosCategory from './pages/videos/create-video-category';
import CreateVideos from './pages/videos/create-videos';
import EditVideoCategory from './pages/videos/edit-video-category';
import EditVideos from './pages/videos/edit-videos';
import './styles/global.scss';

export const Root = () => {
	return (
		<Router>
			<Routes>
				<Route path={ROUTES.LOGIN.path} element={<LoginPage />} />
				<Route path="*" element={<CheckAuth />} />
			</Routes>
		</Router>
	);
};

export const CheckAuth = () => {
	const [authUser, setAuthUser] = useState(() =>
		JSON.parse(localStorage.getItem('authUser') as string),
	);
	const userToken = localStorage.getItem('token');

	useEffect(() => {
		const intervalId = setInterval(() => {
			const storedAuthUser = JSON.parse(
				localStorage.getItem('authUser') as string,
			);
			if (JSON.stringify(storedAuthUser) !== JSON.stringify(authUser)) {
				setAuthUser(storedAuthUser);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [authUser]);

	if (userToken === undefined) {
		return (
			<div
				className="loading"
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
					right: 'auto',
					bottom: 'auto',
				}}
			>
				<Spin />
			</div>
		);
	}
	if (typeof userToken === 'string' && authUser == null) {
		return <GetUser />;
	}
	return <Routers />;
};

const GetUser = () => {
	const { data: qryData } = useQuery<CmsMe>(QueryMe);

	useEffect(() => {
		if (qryData !== undefined) {
			if (qryData?.me === null) {
				localStorage.removeItem('token');
				localStorage.removeItem('authUser');
			}
			if (qryData.me !== null) {
				if (
					qryData.me.userType !== Role.ADMIN &&
					qryData.me.userType !== Role.PHOTOGRAPHER &&
					qryData.me.userType !== Role.SUPER_ADMIN
				) {
					localStorage.removeItem('token');
				} else {
					localStorage.setItem(
						'authUser',
						JSON.stringify({
							id: qryData?.me?.id || '',
							fName: qryData?.me?.fName || '',
							lName: qryData?.me?.lName || '',
							type: qryData?.me?.userType,
						}),
					);
				}
			}
		}
	}, [qryData]);

	return (
		<div
			className="loading"
			style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				right: 'auto',
				bottom: 'auto',
			}}
		>
			<Spin />
		</div>
	);
};

const Routers = () => {
	const authUser = JSON.parse(localStorage.getItem('authUser') as string);

	const navigate = useNavigate();
	useEffect(() => {
		if (authUser === null) {
			navigate(ROUTES.LOGIN.path);
		}
	}, [navigate, authUser]);

	return (
		<>
			{/* If its photographer, show this */}
			{authUser?.type === Role.PHOTOGRAPHER ? (
				<MainLayout isPhotographer={true}>
					<Routes>
						{/* Events Calendar */}
						<Route
							path={ROUTES.PHOTOGRAPHER_HOME.path}
							element={<PhotographerHomeView />}
						/>

						{/* Photos */}
						<Route
							path={genPath(ROUTES.PHOTOGRAPHER_PHOTOS)}
							element={<PhotographerPhotosView />}
						/>

						<Route
							path={ROUTES.PHOTOGRAPHER_EVENTSELFASSIGN.path}
							element={<PhotographerEventSelfAssign />}
						/>

						{/* Photographer Profile */}

						{/* <Route path={ROUTES.PHOTOGRAPHER_PROFILE.path} element={<PhotographerProfile />} /> */}
					</Routes>
				</MainLayout>
			) : (
				(authUser?.type === Role.ADMIN ||
					authUser?.type === Role.SUPER_ADMIN) && (
					<MainLayout isPhotographer={false}>
						<Routes>
							<Route path={ROUTES.ROOT.path} element={<IntroVideo />} />
							{/* START - Intro Video */}

							<Route path={ROUTES.INTRO_VIDEO.path} element={<IntroVideo />} />

							<Route
								path={ROUTES.CREATE_INTRO_VIDEO.path}
								element={<CreateIntroVideo />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_INTRO_VIDEO)}
								element={<EditIntroVideo />}
							/>
							{/* END - Intro Video */}

							{/* START - Stories */}
							<Route path={ROUTES.STORIES.path} element={<Stories />} />
							<Route
								path={ROUTES.CREATE_STORY.path}
								element={<CreateStory />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_STORY)}
								element={<EditStory />}
							/>
							{/* END - Stories */}

							{/* START - Sliders */}
							<Route path={ROUTES.SLIDERS.path} element={<Sliders />} />
							<Route
								path={ROUTES.CREATE_SLIDER.path}
								element={<CreateSlider />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_SLIDER)}
								element={<EditSlider />}
							/>
							{/* END - Sliders */}

							{/* START - Things To Do */}
							<Route path={ROUTES.THINGS_TO_DO.path} element={<ThingsToDo />} />
							<Route
								path={ROUTES.CREATE_THINGS_TO_DO.path}
								element={<CreateThingsToDo />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_THINGS_TO_DO)}
								element={<EditThingsToDo />}
							/>
							{/* END - Things To Do */}

							{/* START - News / Articles */}
							<Route path={ROUTES.NEWS.path} element={<News />} />
							<Route
								path={ROUTES.CREATE_NEWS.path}
								element={<CreateNewsArticle />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_NEWS)}
								element={<EditArticle />}
							/>

							<Route
								path={ROUTES.NEWS_COMMENTS.path}
								element={<NewsComments />}
							/>

							<Route
								path={ROUTES.NEWS_CATEGORIES.path}
								element={<NewsCategory />}
							/>

							<Route
								path={genPath(ROUTES.EDIT_NEWS_CATEGORY)}
								element={<EditArticleCategory />}
							/>

							<Route
								path={ROUTES.CREATE_NEWS_CATEGORY.path}
								element={<CreateArticleCategory />}
							/>

							<Route
								path={ROUTES.EDIT_FEATURED_LIST.path}
								element={<FeaturedList />}
							/>
							{/* END - News / Articles */}

							{/* START - Events */}
							<Route path={ROUTES.EVENTS.path} element={<Events />} />
							<Route
								path={ROUTES.CREATE_EVENT.path}
								element={<CreateEvent />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_EVENT)}
								element={<EditEvent />}
							/>
							<Route
								path={ROUTES.VIEW_PENDING_EVENTS.path}
								element={<EventsActivation />}
							/>
							<Route
								path={genPath(ROUTES.ACTIVATE_EVENT)}
								element={<EditActivateEvent />}
							/>
							<Route
								path={ROUTES.EVENT_PHOTOGRAPHER.path}
								element={<EventPhotographer />}
							/>
							<Route
								path={ROUTES.EVENTS_GENRES.path}
								element={<EventGenre />}
							/>
							{/* END - Events */}

							{/* START - Venues */}
							<Route path={ROUTES.VENUES.path} element={<Venues />} />
							<Route
								path={ROUTES.CREATE_VENUE.path}
								element={<CreateVenues />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_VENUE)}
								element={<EditVenues />}
							/>
							<Route
								path={genPath(ROUTES.REPORT_VENUE)}
								element={<ReportVenue />}
							/>
							{/* END - Venues */}

							{/* START - Offers */}
							<Route path={ROUTES.OFFERS.path} element={<Offers />} />
							<Route
								path={ROUTES.CREATE_OFFERS.path}
								element={<CreateOffers />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_OFFERS)}
								element={<EditOffers />}
							/>
							<Route
								path={ROUTES.OFFERS_CATEGORIES.path}
								element={<OfferCategory />}
							/>
							<Route
								path={ROUTES.VIEW_PENDING_OFFERS.path}
								element={<OffersActivation />}
							/>
							{/* END - Offers */}

							{/* START - Popup Ads */}
							<Route path={ROUTES.POPUP_AD.path} element={<PopupAd />} />
							<Route
								path={ROUTES.CREATE_POPUP_AD.path}
								element={<CreatePopupAd />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_POPUP_AD)}
								element={<EditPopupAd />}
							/>

							{/* END - Popup Ads */}

							{/* START - Discover the best city */}
							<Route
								path={ROUTES.DISCOVER_CITY.path}
								element={<DiscoverCity />}
							/>
							<Route
								path={ROUTES.CREATE_DISCOVER_CITY.path}
								element={<CreateCity />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_DISCOVER_CITY)}
								element={<EditCity />}
							/>
							{/* END - Discover the best city */}

							{/* START - Best places */}
							<Route path={ROUTES.BEST_PLACES.path} element={<BestPlaces />} />
							<Route
								path={ROUTES.CREATE_BEST_PLACES.path}
								element={<CreateBestPlaces />}
							/>
							<Route
								path={genPath(ROUTES.EDIT_BEST_PLACES)}
								element={<EditBestPlaces />}
							/>
							{/* END - Best places */}

							{/* START - Photos */}
							<Route path={ROUTES.PHOTOS.path} element={<Photos />} />

							<Route
								path={genPath(ROUTES.EDIT_PHOTOS)}
								element={<EditPhotos />}
							/>
							{/* END - Photos */}

							{/* START - Videos */}
							<Route path={ROUTES.VIDEOS.path} element={<Videos />} />

							<Route
								path={ROUTES.CREATE_VIDEOS.path}
								element={<CreateVideos />}
							/>

							<Route
								path={genPath(ROUTES.EDIT_VIDEOS)}
								element={<EditVideos />}
							/>

							<Route
								path={ROUTES.VIDEOS_CATEGORY.path}
								element={<VideosCategory />}
							/>

							<Route
								path={ROUTES.CREATE_VIDEOS_CATEGORY.path}
								element={<CreateVideosCategory />}
							/>

							<Route
								path={genPath(ROUTES.EDIT_VIDEOS_CATEGORY)}
								element={<EditVideoCategory />}
							/>
							{/* END - Videos */}

							{/* START - Users */}
							{authUser?.type === Role.SUPER_ADMIN && (
								<Route path={ROUTES.USERS.path} element={<Users />} />
							)}

							<Route
								path={ROUTES.PHOTOGRAPHERS.path}
								element={<Photographers />}
							/>
							<Route
								path={ROUTES.PHOTOGRAPHERS_REQUESTS.path}
								element={<PhotographerRequests />}
							/>

							{/* END - Users */}

							{/* START - Region Settings */}

							<Route
								path={ROUTES.REGION_SETTINGS.path}
								element={<RegionSettings />}
							/>

							{/* END - Region Settings */}
						</Routes>
					</MainLayout>
				)
			)}
		</>
	);
};
