import {
	CameraOutlined,
	CheckCircleFilled,
	CrownOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import { downloadFile } from 'src/lib/axios';
import {
	CmsDisableUser,
	CmsDisableUserVariables,
} from 'src/lib/gql/generated/CmsDisableUser';
import {
	CmsGetPaginatedUsers,
	CmsGetPaginatedUsersVariables,
} from 'src/lib/gql/generated/CmsGetPaginatedUsers';
import {
	CmsUpdateUserType,
	CmsUpdateUserTypeVariables,
} from 'src/lib/gql/generated/CmsUpdateUserType';
import { Role } from 'src/lib/gql/generated/globalTypes';
import {
	MutationDisableUser,
	MutationUpdateUserType,
} from 'src/lib/gql/mutations';
import { QueryGetUsers } from 'src/lib/gql/queries';
import { OffersTable, UsersTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';

const Users = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [userEmail, setUserEmail] = useState<string>('');
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPaginatedUsers, CmsGetPaginatedUsersVariables>(
		QueryGetUsers,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				pageNumber: pageNumber,
				perPage: perPage,
				// type: Role.USER,
				showAll: true,
				email: userEmail,
			},
		},
	);

	const loggedUser = JSON.parse(localStorage.getItem('authUser') as string);
	const p_Users: UsersTable[] = [];

	qryData?.pagination?.users?.result?.forEach((user, i) => {
		return p_Users.push({
			id: user?.id || '',
			about: user?.about || '',
			key: (i + 1).toString(),
			name: `${user?.fName || ''} ${user?.lName || ''}`,
			dob: user?.dob || '',
			email: user?.email || '',
			enabled: user?.enabled || false,
			gender: user?.gender || '',
			userType: user?.userType || '',
		});
	});

	const [disableUser] = useMutation<CmsDisableUser, CmsDisableUserVariables>(
		MutationDisableUser,
	);

	const [updateUserType] = useMutation<
		CmsUpdateUserType,
		CmsUpdateUserTypeVariables
	>(MutationUpdateUserType);

	const tagFilter: any = [];

	qryData?.pagination?.users?.result?.map((user) => {
		return tagFilter.push({
			text: user?.userType || '',
			value: user?.userType || '',
		});
	});

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<OffersTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
			width: 100,
			align: 'center',
			filters: [
				{
					text: 'Male',
					value: 'male',
				},
				{
					text: 'Female',
					value: 'female',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				return record.gender.toLowerCase() === value.toLowerCase();
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 200,
			...tableColumnTextFilterConfig<UsersTable>(),
			onFilter: (value, record) => {
				return record.email
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date of Birth',
			dataIndex: 'dob',
			key: 'dob',
			align: 'center',
			width: 200,
			sorter: (a, b) => {
				return new Date(a.dob).valueOf() - new Date(b.dob).valueOf();
			},
			render: (dob: string) => dob,
		},
		{
			title: 'User Type',
			dataIndex: 'userType',
			key: 'userType',
			width: 150,
			align: 'center',
			filters: tagFilter.filter(
				(user, index, self) =>
					index ===
					self.findIndex(
						(s) => s.userType === user.userType && s.value === user.value,
					),
			),
			onFilter: (value, record) => record.userType.indexOf(value) === 0,
			render: (user: string) => {
				return (
					<Tag
						color={'cyan'}
						key={user}
						style={{
							margin: '2px',
							display: 'block',
						}}
					>
						{user.toUpperCase()}
					</Tag>
				);
			},
		},

		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				return record.enabled === value;
			},
			render: (props: { enabled: boolean; id: string; userType: string }) => {
				const { enabled, id, userType } = props;

				return (
					<Space>
						<CheckCircleFilled
							onClick={async () => {
								if (loggedUser?.id !== id) {
									const rsp = await disableUser({
										variables: {
											userId: id,
											enabled: !enabled,
										},
									});

									if (rsp.data) {
										CustomNotification({
											pageName: 'Users',
											pagePrefix: 'User',
											notificationType: 'updated',
										});

										refetchQuery();
									}
								} else {
									alert("Current logged in user can't be disabled");
								}
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CameraOutlined
							onClick={async () => {
								if (loggedUser?.id === id) return;
								let msg = '';
								if (userType === Role.PHOTOGRAPHER) {
									msg = 'Confirm remove user as photographer?';
								} else {
									msg = 'Confirm make user as photographer?';
								}
								if (!confirm(msg)) return;
								let rsp: FetchResult<CmsUpdateUserType, Record<string, any>>;
								if (userType === Role.PHOTOGRAPHER) {
									rsp = await updateUserType({
										variables: {
											type: Role.USER,
											userId: id,
										},
									});
								} else {
									rsp = await updateUserType({
										variables: {
											type: Role.PHOTOGRAPHER,
											userId: id,
										},
									});
								}
								if (rsp.data) {
									CustomNotification({
										pageName: 'Users',
										pagePrefix: 'User',
										notificationType: 'updated',
									});
									refetchQuery();
								}
							}}
							title={'User is a photographer'}
							style={{
								color:
									userType == Role.PHOTOGRAPHER ? 'black' : 'var(--inactive)',
								width: '16px',
								height: '16px',
							}}
						/>
						<CrownOutlined
							onClick={async () => {
								if (loggedUser?.id === id) return;
								let msg = '';
								if (userType === Role.PHOTOGRAPHER) {
									msg = 'Confirm remove user as admin?';
								} else {
									msg = 'Confirm make user as admin?';
								}
								if (!confirm(msg)) return;
								let rsp: FetchResult<CmsUpdateUserType, Record<string, any>>;
								if (userType === Role.ADMIN) {
									rsp = await updateUserType({
										variables: {
											type: Role.USER,
											userId: id,
										},
									});
								} else {
									rsp = await updateUserType({
										variables: {
											type: Role.ADMIN,
											userId: id,
										},
									});
								}
								if (rsp.data) {
									CustomNotification({
										pageName: 'Users',
										pagePrefix: 'User',
										notificationType: 'updated',
									});
									refetchQuery();
								}
							}}
							title={'User is a photographer'}
							style={{
								color: userType == Role.ADMIN ? 'black' : 'var(--inactive)',
								width: '16px',
								height: '16px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col
				span={24}
				style={{
					display: 'flex',
					marginBottom: '2rem',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Text
					className="sub-header"
					style={{
						fontSize: 'clamp(16px, 1vw, 1.1vw)',
					}}
				>
					Users
				</Text>
				<Button
					onClick={() => {
						downloadFile({
							endpoint: '/csv-export',
						});
					}}
				>
					Export User Data
				</Button>
			</Col>

			<Col span={24}>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Users}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);

							setUserEmail(filters?.email?.toString().toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.users?.paginationInfo?.totalResult || 0,
						}}
						// expandable={{
						// 	expandedRowRender: (record) => (
						// 		<label>
						// 			Admin Comment
						// 			<Input
						// 				suffix={
						// 					<Button
						// 						ghost
						// 						type="primary"
						// 						size="small"
						// 						style={{
						// 							fontSize: 'clamp(9px, 1vw, 10px)',
						// 							color: 'var(--valid)',
						// 							borderColor: 'var(--valid)',
						// 						}}
						// 						onClick={(e) => {
						// 							e.stopPropagation();
						// 						}}
						// 					>
						// 						UPDATE
						// 					</Button>
						// 				}
						// 			/>
						// 		</label>
						// 	),
						// }}
						other={{
							scroll: {
								x: 300,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Users;
