import {
	CheckCircleFilled,
	CloseCircleFilled,
	EyeOutlined,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteVenueType,
	CmsDeleteVenueTypeVariables,
} from 'src/lib/gql/generated/CmsDeleteVenueType';
import { CmsGetBestPlaces } from 'src/lib/gql/generated/CmsGetBestPlaces';
import {
	CmsUpdateVenueType,
	CmsUpdateVenueTypeVariables,
} from 'src/lib/gql/generated/CmsUpdateVenueType';
import {
	MutationDeleteVenueType,
	MutationUpdateVenueType,
} from 'src/lib/gql/mutations';
import { QueryBestPlaces } from 'src/lib/gql/queries';
import { BestPlacesTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const BestPlaces = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetBestPlaces>(QueryBestPlaces, {
		fetchPolicy: 'cache-and-network',
	});

	const [updateBestPlaces] = useMutation<
		CmsUpdateVenueType,
		CmsUpdateVenueTypeVariables
	>(MutationUpdateVenueType, {
		onError(error) {
			CustomNotification({
				pageName: 'Best Places',
				pagePrefix: 'Venue Type',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updatePlaces = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
		) => {
			const rsp = await updateBestPlaces({
				variables: {
					id: id,
					enabled: enabled,
					weight: pos,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Best Places',
					pagePrefix: 'Venue type',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getVenueTypes],
	);

	const [deleteBestPlaces] = useMutation<
		CmsDeleteVenueType,
		CmsDeleteVenueTypeVariables
	>(MutationDeleteVenueType);

	const deleteQry = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await deleteBestPlaces({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Best Places',
					pagePrefix: 'Venue type',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.getVenueTypes],
	);
	const p_BestPlaces: BestPlacesTable[] = [];
	const p_BestPlaces_Query = qryData?.getVenueTypes?.map((place, i) => {
		return p_BestPlaces.push({
			id: place?.id || '',
			name: place?.name || '',
			slug: place?.slug || '',
			enabled: place?.enabled || false,
			key: (i + 1).toString() || '',
			tag: place?.hTag || '',
			weight: place?.weight || 0,
		});
	});

	// p_BestPlaces.sort((a, b) => a.weight - b.weight);

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => a.weight - b.weight,

			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updatePlaces(id, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'title',
			width: 200,
			...tableColumnTextFilterConfig<BestPlacesTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Tag',
			key: 'tag',
			dataIndex: 'tag',
			width: 200,
			align: 'center',
			render: (tag: string) => (
				<>
					<Tag
						color={'geekblue'}
						key={tag}
						style={{
							margin: '2px',
							display: 'block',
						}}
					>
						{tag.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { enabled: boolean; id: string; slug: string }) => {
				const { enabled, id, slug } = props;
				return (
					<Space>
						<a
							href={`https://dubainight.com/best-places/${slug}`}
							target="_blank"
							rel="noreferrer"
						>
							<EyeOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</a>
						<Link to={`${ROUTES.EDIT_BEST_PLACES.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updatePlaces(id, !enabled, undefined);
							}}
							style={{
								color: enabled === true ? 'var(--valid)' : 'var(--inactive)',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteQry(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Best Places</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_BEST_PLACES.path}>
						<Button className="btn-main">
							Create New Place
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_BestPlaces}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default BestPlaces;
