import {
	DeleteFilled,
	LoadingOutlined,
	ReloadOutlined,
	ShakeOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Empty, Image, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import {
	CmsDeleteEventPhotos,
	CmsDeleteEventPhotosVariables,
} from 'src/lib/gql/generated/CmsDeleteEventPhotos';
import {
	CmsGetEventPhotographer,
	CmsGetEventPhotographerVariables,
} from 'src/lib/gql/generated/CmsGetEventPhotographer';
import {
	CmsUpdateEventPhotosMutation,
	CmsUpdateEventPhotosMutationVariables,
} from 'src/lib/gql/generated/CmsUpdateEventPhotosMutation';
import {
	MutationsDeleteEventPhotos,
	MutationUpdateEventPhotos,
} from 'src/lib/gql/mutations';
import { QueryGetEventPhotographer } from 'src/lib/gql/queries';
import PhotographerUpload from './photographer-upload';

interface Params {
	id: string;
}
const PhotographerPhotosView = () => {
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();

	const [showDescription, setShowDescription] = useState<boolean>(false);
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetEventPhotographer, CmsGetEventPhotographerVariables>(
		QueryGetEventPhotographer,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				id: params.id ?? '',
			},
		},
	);

	const [deleteEventPhotos] = useMutation<
		CmsDeleteEventPhotos,
		CmsDeleteEventPhotosVariables
	>(MutationsDeleteEventPhotos, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Photos',
				pagePrefix: 'Photo',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [shuffleEventPhotos] = useMutation<
		CmsUpdateEventPhotosMutation,
		CmsUpdateEventPhotosMutationVariables
	>(MutationUpdateEventPhotos, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Photos',
				pagePrefix: 'Photo',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<div>
			<Card title={qryData?.getEventPhotographer?.eventDate?.event?.name}>
				<div>
					Venue:{' '}
					<b>
						{qryData?.getEventPhotographer?.eventDate?.eventParent?.venue?.name}
					</b>
				</div>
				<div>
					Date: <b>{qryData?.getEventPhotographer?.eventDate?.eDate}</b>
				</div>
			</Card>
			<PhotographerUpload
				eventDateId={qryData?.getEventPhotographer?.eventDate?.id || '-1'}
				reFetch={refetchQuery}
				onChange={() => {
					setShowDescription(true);
				}}
			/>

			<Card
				title="All photos"
				bodyStyle={{
					display: 'flex',
					flexWrap: 'wrap',
					alignItems: 'center',
				}}
				extra={
					<>
						{loading ? (
							<Spin
								indicator={<LoadingOutlined />}
								size="large"
								spinning={true}
							/>
						) : (
							<>
								<Button
									shape="circle"
									id="shufflebtn"
									name="shufflebtn"
									type="default"
									onClick={async () => {
										const rsp = await shuffleEventPhotos({
											variables: {
												data: {
													eventPhotoId:
														qryData?.getEventPhotographer?.eventPhotos
															?.map((ph) => ph.id || '')
															.sort(() => Math.random() - 0.5) || [],
													changeOrder: true,
												},
											},
										});

										if (rsp.data)
											CustomNotification({
												pageName: 'Photos',
												pagePrefix: 'Photos order',
												notificationType: 'updated',
											});
										refetchQuery();
									}}
								>
									<ShakeOutlined />
								</Button>
								<label
									htmlFor="shufflebtn"
									style={{
										paddingLeft: '10px',
										marginRight: '15px',
										cursor: 'pointer',
									}}
								>
									Shuffle Photos
								</label>
								<Button
									id="refreshbtn"
									name="refreshbtn"
									shape="circle"
									type={showDescription ? 'primary' : 'default'}
									onClick={() => {
										refetchQuery();
										setShowDescription(false);
									}}
								>
									<ReloadOutlined />
								</Button>

								<label
									htmlFor="refreshbtn"
									style={{
										paddingLeft: '10px',
										cursor: 'pointer',
									}}
								>
									{showDescription ? 'Update View' : 'Refresh View'}
								</label>
							</>
						)}
					</>
				}
			>
				{qryData?.getEventPhotographer?.eventPhotos === null && <Empty />}

				{qryData?.getEventPhotographer?.eventPhotos?.map((ph) => {
					return (
						<Col key={ph.id} span={24} lg={12} xl={8}>
							<Card.Grid
								key={ph.id}
								style={{
									width: '100%',
									height: '200px',
								}}
							>
								<Image
									wrapperStyle={{
										height: '100%',
									}}
									width={100}
									alt="img"
									src={ph.media?.url}
									preview={true}
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										objectPosition: 'center',
									}}
								/>

								<Button
									name="Delete"
									type="primary"
									style={{
										zIndex: 50,
										position: 'absolute',
										right: 0,
										top: 0,
										background: 'red',
										border: 'none',
									}}
									onClick={async () => {
										const conf = confirm('Delete selected photo?');

										if (conf) {
											const rsp = await deleteEventPhotos({
												variables: {
													ids: [ph?.id || ''],
												},
											});

											if (rsp.data) {
												CustomNotification({
													pageName: 'Photos',
													pagePrefix: 'Photo',
													notificationType: 'deleted',
												});
											}
											refetchQuery();
										}
									}}
									icon={<DeleteFilled />}
								/>
							</Card.Grid>
						</Col>
					);
				})}
			</Card>
		</div>
	);
};

export default PhotographerPhotosView;
