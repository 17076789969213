import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Radio,
	Row,
	Select,
	Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useRef, useState } from 'react';
import SlugInput from 'src/components/ui/slug-input';
import TagInput from 'src/components/ui/tag-input';
import { VenueForm } from 'src/lib/form-interfaces';
import { CmsGetBestPlaces } from 'src/lib/gql/generated/CmsGetBestPlaces';
import { CmsGetDiscoverTheCity } from 'src/lib/gql/generated/CmsGetDiscoverTheCity';
import { VenueStatus } from 'src/lib/gql/generated/globalTypes';
import { QueryBestPlaces, QueryDiscoverTheCity } from 'src/lib/gql/queries';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import EditorComp from '../editor';
import MediaUpload from '../media-upload';

export interface VenueCardSaveData extends VenueForm {
	coverImageId: string | null;
	mediaIds: string[];
	menuIds: string[];
	logoId: string | null;
	numberWight: number | null;
}
interface Props {
	venueName?: string; // Event Name
	venueSlug?: string; //Optional - Link for story
	venueInfo?: string; // Event Information and Description
	venueTag?: string;
	venueFamousFor?: string;
	venueLocation?: string; // How to get there - Google Map URL
	venueLocationLongitude?: string; // How to get there - Google Map URL
	venueLocationLatitude?: string; // How to get there - Google Map URL
	venueBookingURL?: string | null; // CTA Links -> Booking URL
	venueContactNumber?: string | null; // CTA Links -> Contact Number
	venueLogo?: Media; // Event Poster / Banner
	coverImage?: Media; // Event Poster / Banner
	venueImages?: Media[];
	venueMenuImages?: Media[];
	venuePartner?: boolean;
	venueDateTiming?: string;
	venueMap?: string | undefined;
	venueDressCode?: string;
	venueVirtualTour?: string;
	venueMeta?: string;
	venueArea?: string;
	canDelete?: boolean;
	venueTypes?: string[];
	weight?: number | undefined;
	verified?: boolean;
	status?: VenueStatus;
	properName?: string;

	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		name,
		dressCode,
		meta,
		map,
		coverImageId,
		mediaIds,
		menuIds,
		logoId,
		info,
		partner,
		verified,
		htag,
		slug,
		areaId,
		ctaNumber,
		ctaLink,
		longitude,
		latitude,
		venueTypeIds,
		dateTiming,
		famousFor,
		weight,
		virtualTour,
		properName,
	}: VenueCardSaveData) => void;
}
const { Option } = Select;

const VenueCard = (props: Props) => {
	const [logo, setLogo] = useState<Media | null>(props.venueLogo || null);
	const medias = useRef<Media[]>([...(props.venueImages || [])]);
	const menuMedias = useRef<Media[]>([...(props.venueMenuImages || [])]);
	const [coverImage, setCoverImage] = useState<Media | null>(
		props.coverImage || null,
	);
	const [rerender, setRerender] = useState(false);

	const [venuePhotoKey, setVenuePhotoKey] = useState(
		`uploadVenuePhoto${new Date().getTime()}`,
	);
	const [venueMenuKey, setVenueMenuKey] = useState(
		`uploadMenuPhoto${new Date().getTime()}`,
	);
	const [form] = useForm<VenueForm>();
	const createVenue = async (data: VenueForm) => {
		// if (logo === null || logo === undefined) {
		// 	CustomNotification({
		// 		pageName: 'Venues',
		// 		errorName: 'Venue Logo',
		// 		notificationType: 'error',
		// 	});
		// 	return;
		// }

		// if (coverImage === null || coverImage === undefined) {
		// 	CustomNotification({
		// 		pageName: 'Venues',
		// 		errorName: 'Cover Image',
		// 		notificationType: 'error',
		// 	});
		// 	return;
		// }
		props.onSave({
			name: data.name,
			dressCode: data.dressCode || undefined,
			meta: data.meta || undefined,
			map: data.map || undefined,
			coverImageId: coverImage?.id || null,
			mediaIds:
				medias.current.map((m) => {
					return m.id;
				}) || null,
			menuIds:
				menuMedias.current.map((m) => {
					return m.id;
				}) || null,
			logoId: logo?.id || null,

			info: data.info || undefined,
			partner: data.partner,
			verified: data.verified,
			htag: data.htag,
			slug: data.slug,
			areaId: data.areaId,
			ctaNumber: data.ctaNumber || undefined,
			ctaLink: data.ctaLink || undefined,
			longitude: data.longitude,
			latitude: data.latitude,
			venueTypeIds: data.venueTypeIds,
			dateTiming: data.dateTiming,
			famousFor: data.famousFor,
			weight: data.weight,
			numberWight: (data.weight && parseInt(data.weight)) || null,
			virtualTour: data.virtualTour,
			status: data.status,
			properName: data.properName,
		});
	};

	useEffect(() => {
		// Do a rerender when buttons are clicked
	}, [rerender]);

	const { data: qryData } =
		useQuery<CmsGetDiscoverTheCity>(QueryDiscoverTheCity);

	const { data: venueTypeData } = useQuery<CmsGetBestPlaces>(QueryBestPlaces);

	return (
		<Form
			form={form}
			onFinish={createVenue}
			style={{
				width: '100%',
			}}
			onValuesChange={(v) => {
				//
			}}
		>
			<Row style={{ width: '100%', marginTop: '1em' }} gutter={16}>
				{/* Name / Title */}

				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}
				>
					<Col span={24} md={18}>
						<label className="input-fields-label">Venue Name</label>

						<Form.Item
							rules={[{ required: true }]}
							name="name"
							hasFeedback
							initialValue={props.venueName}
						>
							<Input
								type="text"
								placeholder="Venue Name"
								className="input-fields"
								value={props.venueName}
							/>
						</Form.Item>
					</Col>
					<Col span={24} md={18}>
						<label className="input-fields-label">
							Venue Proper Name (without special character)
						</label>

						<Form.Item
							rules={[{ required: false }]}
							name="properName"
							hasFeedback
							initialValue={props.properName}
						>
							<Input
								type="text"
								placeholder="Venue Proper Name (without special character)"
								className="input-fields"
								value={props.properName}
							/>
						</Form.Item>
					</Col>

					<Col
						span={24}
						md={6}
						style={{
							display: 'flex',
						}}
					>
						<Text>DubaiNight Partner:</Text>
						<Form.Item
							name="partner"
							initialValue={props.venuePartner}
							rules={[{ required: true }]}
						>
							<Radio.Group
								style={{
									height: '40px',
								}}
							>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>
				</div>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}
				>
					<Col span={24} md={18}>
						<label className="input-fields-label">Position</label>

						<Form.Item
							name="weight"
							hasFeedback
							rules={[
								{
									validator: (rule, value: string, back) => {
										if (value !== undefined) {
											if (value.length > 4) {
												back('Too large');
												return;
											}
											if (value !== '' && parseInt(value) < 1) {
												back('Minimum value is 1');
												return;
											}
										}
										back(undefined);
										return;
									},
								},
							]}
							initialValue={props.weight}
						>
							<Input
								type="number"
								min="1"
								placeholder="Position / Sequence"
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					<Col
						span={24}
						md={6}
						style={{
							display: 'flex',
							alignItems: 'baseline',
						}}
					>
						<Text>Verified:</Text>
						<Form.Item
							name="verified"
							initialValue={props.verified}
							rules={[{ required: true }]}
						>
							<Radio.Group>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>
				</div>

				<Col span={24} lg={12}>
					<label className="input-fields-label">Slug</label>

					<Form.Item
						name="slug"
						hasFeedback
						initialValue={props.venueSlug}
						rules={[{ required: true }]}
					>
						<SlugInput />
					</Form.Item>
				</Col>

				<Col span={24} lg={12}>
					<label className="input-fields-label">Venue Tag</label>

					<Form.Item
						name="htag"
						hasFeedback
						initialValue={props.venueTag}
						rules={[{ required: true }]}
					>
						<TagInput prefix={'Venue'} />
					</Form.Item>
				</Col>

				{/* Area / Select a Venue Type */}
				<Col span={24} md={12}>
					<label className="input-fields-label">Area / City</label>

					<Form.Item
						initialValue={props.venueArea}
						name="areaId"
						hasFeedback
						rules={[{ required: true }]}
					>
						<Select
							style={{ width: '100%' }}
							placeholder="Area"
							size="middle"
							className="bg-select"
						>
							{qryData?.getAreas?.map((area, i) => {
								return (
									<Option key={area?.id} value={area?.id || ''}>
										{area?.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={24} md={12}>
					<label className="input-fields-label">Venue Type</label>

					<Form.Item
						initialValue={props.venueTypes}
						name="venueTypeIds"
						hasFeedback
						rules={[{ required: true }]}
					>
						<Select
							suffixIcon={null}
							mode="multiple"
							style={{ width: '100%' }}
							placeholder="Venue Type"
							size="middle"
							className="multiple-custom-select"
						>
							{venueTypeData?.getVenueTypes?.map((venueType) => {
								return (
									<Option key={venueType?.id} value={venueType?.id || ''}>
										{venueType?.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<label className="input-fields-label">Venue Status</label>

					<Form.Item
						initialValue={props.status || VenueStatus.OPEN}
						name="status"
						hasFeedback
						rules={[{ required: true }]}
					>
						<Select
							style={{ width: '100%' }}
							placeholder="Status"
							size="middle"
							className="bg-select"
						>
							{Object.keys(VenueStatus)?.map((status) => {
								return (
									<Option key={status} value={status}>
										{status}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				{/* Offers / Music Genre */}

				{/* DIVIDER - EVENT ADDITIONAL INFO */}
				<Col
					span={24}
					style={{
						margin: '0.5em 0',
					}}
				>
					<Text className="sub-header">Venue Information</Text>
					<Divider
						style={{
							margin: 0,
						}}
					/>
				</Col>

				<Col
					span={24}
					style={{
						marginTop: '1em',
					}}
				>
					<Row
						className="input-fields"
						style={{
							marginBottom: '0',
						}}
					>
						<label className="input-fields-label">
							Venue Info / Description
						</label>

						<Form.Item
							name="info"
							initialValue={props.venueInfo}
							style={{
								width: '100%',
							}}
						>
							<EditorComp
								value={props.venueInfo || '<p>Venue Info / Description</p>'}
							/>
						</Form.Item>
					</Row>
				</Col>

				<Col
					span={24}
					style={{
						marginTop: '1em',
					}}
				>
					<Row
						className="input-fields"
						style={{
							marginBottom: '0',
						}}
					>
						<label className="input-fields-label">Famous For</label>

						<Form.Item
							name="famousFor"
							initialValue={props.venueFamousFor}
							style={{
								width: '100%',
							}}
						>
							<EditorComp value={props.venueFamousFor || '<p>Famous For</p>'} />
						</Form.Item>
					</Row>
				</Col>

				<Col span={24} md={12}>
					<Row
						className="input-fields"
						style={{
							marginBottom: '0',
						}}
					>
						<label className="input-fields-label">Dates & Timings</label>

						<Form.Item
							name="dateTiming"
							initialValue={props.venueDateTiming}
							style={{
								width: '100%',
							}}
						>
							<EditorComp value={props.venueDateTiming || 'Dates & Timings'} />
						</Form.Item>
					</Row>
				</Col>

				<Col span={24} md={12}>
					<Row
						className="input-fields"
						style={{
							marginBottom: '0',
						}}
					>
						<label className="input-fields-label">Dress Code</label>

						<Form.Item
							name="dressCode"
							initialValue={props.venueDressCode}
							style={{
								width: '100%',
							}}
						>
							<EditorComp value={props.venueDressCode || 'Dress Code'} />
						</Form.Item>
					</Row>
				</Col>

				<Col span={24}>
					<Row
						style={{
							marginTop: '1rem',
							marginBottom: '0',
						}}
					>
						<label className="input-fields-label">Virtual Tour Link</label>

						<Form.Item
							name="virtualTour"
							initialValue={props.venueVirtualTour}
							style={{
								width: '100%',
							}}
						>
							<Input
								type="text"
								prefix={<LinkOutlined />}
								placeholder="Virtual Tour Link"
								className="input-fields"
							/>
						</Form.Item>
					</Row>
				</Col>

				{/* DIVIDER - VENUE ADDITIONAL INFO */}
				<Col
					span={24}
					style={{
						margin: '0.5em 0',
					}}
				>
					<Text className="sub-header">Venue Other Information</Text>
					<Divider
						style={{
							margin: 0,
						}}
					/>
				</Col>

				{/* Event Location */}
				<Col span={24}>
					<div>
						(
						<a
							target="_blank"
							href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
							rel="noreferrer"
						>
							Click here to get it
						</a>
						)
					</div>
				</Col>

				<Col
					span={24}
					md={12}
					style={{
						marginTop: '0.5em',
					}}
				>
					<label className="input-fields-label">Google Map Place ID</label>

					<Form.Item name="map" initialValue={props.venueMap}>
						<Input
							prefix={<LinkOutlined />}
							type="text"
							placeholder="Google Map Place ID (eg: ChIJRcbZaklDXz4RYlEphFBu5r0)"
							className="input-fields"
							// value={props.venueLocation}
						/>
					</Form.Item>
				</Col>

				<Col
					span={24}
					md={6}
					style={{
						marginTop: '0.5em',
					}}
				>
					<label className="input-fields-label">Latitude</label>

					<Form.Item
						rules={[
							{
								required: true,
								validator: (rule, value, callback) => {
									try {
										if (!value || value === '') throw new Error('');
										const val = parseFloat(value);
										if (val < -90 || val > 90) throw new Error('');
										else callback();
									} catch (err: any) {
										callback('invalid value');
									}
								},
							},
						]}
						name="latitude"
						hasFeedback
						initialValue={props.venueLocationLatitude}
					>
						<Input
							type="text"
							placeholder="Latitude"
							className="input-fields"
						/>
					</Form.Item>
				</Col>
				<Col
					span={24}
					md={6}
					style={{
						marginTop: '0.5em',
					}}
				>
					<label className="input-fields-label">Longitude</label>

					<Form.Item
						rules={[
							{
								required: true,
								validator: (rule, value, callback) => {
									try {
										if (!value || value === '') throw new Error('');
										const val = parseFloat(value);
										if (val < -180 || val > 180) throw new Error('');
										else callback();
									} catch (err: any) {
										callback('invalid value');
									}
								},
							},
						]}
						name="longitude"
						hasFeedback
						initialValue={props.venueLocationLongitude}
					>
						<Input
							type="text"
							placeholder="Longitude"
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				<Col span={24} md={16}>
					<label className="input-fields-label">Booking URL</label>

					<Form.Item
						rules={[
							{
								validator: (rule, value, callback) => {
									if (!value || value.startsWith('http')) {
										callback();
									} else {
										callback('Link must start with "http"');
									}
								},
							},
						]}
						name="ctaLink"
						initialValue={props.venueBookingURL}
					>
						<Input
							prefix={<LinkOutlined />}
							type="text"
							placeholder="Booking URL"
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				<Col span={24} md={8}>
					<label className="input-fields-label">Contact Number</label>

					<Form.Item name="ctaNumber" initialValue={props.venueContactNumber}>
						<Input
							type="tel"
							placeholder="Contact Number"
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				<Col span={24}>
					<label className="input-fields-label">SEO Meta Description</label>

					<Form.Item name="meta" initialValue={props.venueMeta}>
						<Input.TextArea
							placeholder="Meta Description (SEO)"
							className="input-fields"
							rows={5}
						/>
					</Form.Item>
				</Col>

				{/* Venue Logo / Images */}

				{/* DIVIDER - VENUE MEDIA */}
				<Col
					span={24}
					md={12}
					style={{
						margin: '0.5em 0',
					}}
				>
					<Text className="sub-header">Venue Logo</Text>
					<Divider
						style={{
							margin: '0.5em 0',
						}}
					/>

					<MediaUpload
						folder={MediaFoldersNames.misc}
						media={logo || undefined}
						onMediaUploaded={(media) => {
							setLogo(media);
						}}
						accept="IMAGE"
						placeholderText="Drag and drop a venue logo image."
					/>
				</Col>

				<Col
					span={24}
					md={12}
					style={{
						margin: '0.5em 0',
					}}
				>
					<Text className="sub-header">Venue Cover Image</Text>
					<Divider
						style={{
							margin: '0.5em 0',
						}}
					/>

					<MediaUpload
						folder={MediaFoldersNames.misc}
						media={coverImage || undefined}
						onMediaUploaded={(media) => {
							setCoverImage(media || null);
						}}
						accept="IMAGE"
						placeholderText={'Drag and drop a venue cover image.'}
					/>
				</Col>

				<Col
					span={24}
					style={{
						margin: '0.5em 0',
						marginTop: '3rem',
					}}
				>
					{/* DIVIDER - VENUE MEDIA */}

					<Text className="sub-header">Venue Media</Text>
					<Divider
						style={{
							margin: '0.5em 0',
						}}
					/>
					<Row gutter={16}>
						{medias.current.map((media, i) => {
							return (
								<Col
									key={`mediaUpload${i}`}
									span={24}
									lg={8}
									style={{
										margin: '0.5em 0',
									}}
								>
									<MediaUpload
										folder={MediaFoldersNames.venue}
										key={`mediaUpload${i}`}
										onMediaUploaded={(media) => {
											medias.current[i] = media;
										}}
										media={media}
										accept="IMAGE"
										height={'150px'}
									/>
									<Button
										style={{
											zIndex: 100000,
											position: 'absolute',
											top: 10,
											right: 30,
										}}
										onClick={() => {
											medias.current.splice(i, 1);
											setRerender(!rerender);
										}}
									>
										<DeleteOutlined />
									</Button>
								</Col>
							);
						})}

						<Col
							span={24}
							lg={8}
							style={{
								margin: '0.5em 0',
							}}
						>
							<MediaUpload
								folder={MediaFoldersNames.venue}
								key={venuePhotoKey}
								onMediaUploaded={(media) => {
									medias.current.push(media);
									setVenuePhotoKey(Math.random().toString());
								}}
								media={undefined}
								accept="IMAGE"
								height={'150px'}
								placeholderText={'Drag and drop a photo'}
							/>
						</Col>
					</Row>
				</Col>

				<Col
					span={24}
					style={{
						margin: '0.5em 0',
					}}
				>
					{/* DIVIDER - VENUE MEDIA */}

					<Text className="sub-header">Venue Menu</Text>
					<Divider
						style={{
							margin: '0.5em 0',
						}}
					/>
					<Row gutter={16}>
						{menuMedias.current.map((media, i) => {
							return (
								<Col
									key={`mediaUpload${i}`}
									span={24}
									lg={8}
									style={{
										margin: '0.5em 0',
									}}
								>
									<MediaUpload
										folder={MediaFoldersNames.venue}
										key={`mediaUpload${i}`}
										onMediaUploaded={(media) => {
											menuMedias.current[i] = media;
										}}
										media={media}
										accept="IMAGE"
										height={'150px'}
									/>
									<Button
										style={{
											zIndex: 100000,
											position: 'absolute',
											top: 10,
											right: 30,
										}}
										onClick={() => {
											menuMedias.current.splice(i, 1);
											setRerender(!rerender);
										}}
									>
										<DeleteOutlined />
									</Button>
								</Col>
							);
						})}

						<Col
							span={24}
							lg={8}
							style={{
								margin: '0.5em 0',
							}}
						>
							<MediaUpload
								folder={MediaFoldersNames.venueMenu}
								key={venueMenuKey}
								onMediaUploaded={(media) => {
									menuMedias.current.push(media);
									setVenueMenuKey(Math.random().toString());
								}}
								media={undefined}
								accept="IMAGE"
								height={'150px'}
								placeholderText={'Drag and drop a menu image'}
							/>
						</Col>
					</Row>
				</Col>

				<Col span={24}>
					<hr style={{ opacity: '0.25' }} />

					<Row justify={'center'}>
						<Space size="small">
							<Button className="btn-main save" htmlType="submit">
								Save
							</Button>

							{props.canDelete ? (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							) : (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default VenueCard;
