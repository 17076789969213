import { useMutation, useQuery } from '@apollo/client';
import { Col, Input, Row, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateVenueStoryMutation,
	CmsCreateVenueStoryMutationVariables,
} from 'src/lib/gql/generated/CmsCreateVenueStoryMutation';
import { CmsVenueNamesQuery } from 'src/lib/gql/generated/CmsVenueNamesQuery';
import { CreateVenueStory } from 'src/lib/gql/mutations';
import { VenueNames } from 'src/lib/gql/queries';
import StoryCard from '../../components/ui/stories/story-card';

const CreateStory = () => {
	const navigate = useNavigate();
	const [position, setPosition] = useState<string | null>(null);

	const [venueSelected, setVenueSelected] = useState<string | null>(null);

	const [createVenueStory] = useMutation<
		CmsCreateVenueStoryMutation,
		CmsCreateVenueStoryMutationVariables
	>(CreateVenueStory, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const storyTags = useRef<string[]>([]);

	const { data: qryVenues } = useQuery<CmsVenueNamesQuery>(VenueNames);

	// const tagSelectionChange = useCallback((val: string[]) => {
	// 	storyTags.current = val;
	// }, []);

	function onChange(value: string) {
		setVenueSelected(value);
	}

	const { Option } = Select;

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Select a Venue
				</Text>
				<Row wrap={false}>
					<Select
						showSearch
						value={venueSelected || undefined}
						style={{ width: 200 }}
						placeholder="Select a Venue"
						optionFilterProp="children"
						onChange={onChange}
					>
						{qryVenues?.getVenues?.map((venue) => {
							return (
								<Option key={`venue${venue?.id}`} value={venue?.id || ''}>
									{venue?.name}
								</Option>
							);
						})}
					</Select>
					<Input
						style={{ width: '200px', marginLeft: '10px' }}
						type="number"
						onChange={(v) => {
							setPosition(v.target.value || null);
						}}
						value={position || undefined}
						placeholder="Position / Sequence No."
						min="1"
						// value={props.storyPosition}
					/>
				</Row>
				{/* <SelectTags
					initialValues={storyTags.current}
					onSelectionChange={tagSelectionChange}
				/> */}
			</Col>

			{/* Story Component - Props explained in the component */}
			<StoryCard
				onSave={async (data) => {
					if (venueSelected === null) {
						CustomNotification({
							pageName: 'Stories',
							errorName: 'Date',
							notificationType: 'error',
						});
						return;
					}
					const rsp = await createVenueStory({
						variables: {
							venueId: venueSelected,
							hTags: storyTags.current,
							storyHTags: storyTags.current || [],
							mediaId: data.mediaId,
							storyDates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
							itemWeight: data.position,
							link: data.link,
							weight: position ? Number.parseInt(position) : null,
						},
					});
					if (rsp.data) {
						CustomNotification({
							pageName: 'Stories',
							pagePrefix: 'Story',
							notificationType: 'created',
						});
						navigate(
							`${ROUTES.EDIT_STORY.path}/${rsp.data.createVenueStory?.id}`,
						);
					}
				}}
				type={'create'}
				bannerTitle={'Create New Story'}
			/>
		</Row>
	);
};

export default CreateStory;
