import { useQuery } from '@apollo/client';
import { isArray } from '@apollo/client/cache/inmemory/helpers';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { CmsGetAllTags } from 'src/lib/gql/generated/CmsGetAllTags';
import { AllTags } from 'src/lib/gql/queries';

interface Props {
	initialValues: string[];
	filter?: string;
	placeholder?: string;
	onSelectionChange: (selections: string[]) => void;
	singleSelect?: boolean;
	onInit?: (selections: string[]) => void;
}

const SelectTags = ({
	initialValues,
	filter = '',
	placeholder = "Select tags (Pages where they'll appear)",
	onSelectionChange,
	onInit,
	singleSelect = false,
}: Props): JSX.Element => {
	const { Option } = Select;
	const allOptions: string[] = [];
	const { data: qryTags } = useQuery<CmsGetAllTags>(AllTags);
	const [storyTags, setStoryTags] = useState<string[] | null>(null);
	useEffect(() => {
		setStoryTags(initialValues);
		if (onInit) onInit(initialValues);
	}, []);

	const onSelectChange = (val: string[]) => {
		if (val.includes('selectAll')) {
			val = allOptions;
		}
		if (!isArray(val)) {
			val = [val];
		}
		onSelectionChange(val);
		setStoryTags(val);
	};

	return (
		<Select
			mode={singleSelect ? undefined : 'multiple'}
			allowClear
			showSearch
			suffixIcon={null}
			style={{ width: '100%' }}
			placeholder={placeholder}
			onChange={onSelectChange}
			value={storyTags || []}
			size="middle"
			className="multiple-custom-select"
		>
			<>
				<Option key={`selectAllOption`} value={'selectAll'}>
					Select All
				</Option>
				{qryTags &&
					Object.getOwnPropertyNames(qryTags).map((obj) => {
						return qryTags?.[obj]
							?.filter((a, i) => a.hTag.includes(filter))
							?.map((ele: any) => {
								allOptions.push(ele?.hTag || '');

								return (
									<Option key={`${obj}${ele?.id}`} value={ele?.hTag || ''}>
										{ele?.hTag}
									</Option>
								);
							});
					})}
			</>
		</Select>
	);
};

export default SelectTags;
