import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCategoryCard from 'src/components/ui/videos/video-category-card';
import { ROUTES } from 'src/config/route';
import { CategoryForm } from 'src/lib/form-interfaces';
import {
	CmsCreateArticleType,
	CmsCreateArticleTypeVariables,
} from 'src/lib/gql/generated/CmsCreateArticleType';
import { MutationCreateArticleType } from 'src/lib/gql/mutations';

const CreateArticleCategory = () => {
	const navigate = useNavigate();

	const [CreateCategory] = useMutation<
		CmsCreateArticleType,
		CmsCreateArticleTypeVariables
	>(MutationCreateArticleType, {
		onError(error) {
			CustomNotification({
				pageName: 'News Categories',
				pagePrefix: 'News Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: CategoryForm) => {
		const rsp = await CreateCategory({
			variables: {
				data: {
					name: data.name,
					weight: data.weight ? parseInt(data.weight.toString()) : null,
					slug: data.slug || '',
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'News Categories',
				pagePrefix: 'News Category',
				notificationType: 'created',
			});

			navigate(
				`${ROUTES.EDIT_NEWS_CATEGORY.path}/${rsp.data.createArticleType?.id}`,
			);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create News Category</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				<VideoCategoryCard isNewsCategory={true} onSave={createQry} />
			</Row>
		</Row>
	);
};

export default CreateArticleCategory;
