import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import {
	CmsDeletePopupAd,
	CmsDeletePopupAdVariables,
} from 'src/lib/gql/generated/CmsDeletePopupAd';
import { CmsGetPopupAds } from 'src/lib/gql/generated/CmsGetPopupAds';
import {
	CmsUpdatePopupAd,
	CmsUpdatePopupAdVariables,
} from 'src/lib/gql/generated/CmsUpdatePopupAd';
import {
	MutationDeletePopupAd,
	MutationUpdatePopupAd,
} from 'src/lib/gql/mutations';
import { QueryGetPopupAds } from 'src/lib/gql/queries';
import { PopupAdTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const PopupAd = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPopupAds>(QueryGetPopupAds, {
		fetchPolicy: 'cache-and-network',
	});

	const [UpdatePopupAd] = useMutation<
		CmsUpdatePopupAd,
		CmsUpdatePopupAdVariables
	>(MutationUpdatePopupAd, {
		onError(error) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateVideo = useCallback(
		async (id: string, enabled: boolean | undefined) => {
			const rsp = await UpdatePopupAd({
				variables: {
					id: id,
					enabled: enabled,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Popup Ad',
					pagePrefix: 'PopupAd',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getPopupAds],
	);

	const [DeletePopupAd] = useMutation<
		CmsDeletePopupAd,
		CmsDeletePopupAdVariables
	>(MutationDeletePopupAd, {
		onError(error) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteVideo = useCallback(
		async (id: string) => {
			if (!confirm('Confirm delete?')) return;
			const rsp = await DeletePopupAd({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Popup Ad',
					pagePrefix: 'PopupAd',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.getPopupAds],
	);

	const p_PopupAds: PopupAdTable[] =
		qryData?.getPopupAds?.map((video, i) => {
			return {
				id: video?.id || '',
				name: video?.name || '',
				enabled: video?.enabled || false,
				key: (i + 1).toString() || '',
			};
		}) || [];

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'title',
			width: 300,
			...tableColumnTextFilterConfig<PopupAdTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Link',
			dataIndex: 'link',
			key: 'link',
			width: 300,
			ellipsis: true,
		},

		{
			title: 'Edit',
			key: 'enabled',
			width: 100,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { id: string; enabled: boolean }) => {
				const { id, enabled } = props;
				return (
					<Space>
						<Link to={`${ROUTES.EDIT_POPUP_AD.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateVideo(id, !enabled);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteVideo(id);
							}}
							style={{
								color: 'var(--invalid)',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Popup Ads</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
				>
					<Link to={ROUTES.CREATE_POPUP_AD.path}>
						<Button className="btn-main">
							Create New Popup Ad
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<PopupAdTable>
						data={p_PopupAds}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default PopupAd;
