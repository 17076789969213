import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateThingsToDo as CTD,
	CmsCreateThingsToDoVariables,
} from 'src/lib/gql/generated/CmsCreateThingsToDo';
import { MutationCreateThingsToDo } from 'src/lib/gql/mutations';
import ThingsToDoCard from '../../components/ui/things-to-do/things-to-do-card';

const CreateThingsToDo = () => {
	const navigate = useNavigate();

	const [CreateThingsToDo] = useMutation<CTD, CmsCreateThingsToDoVariables>(
		MutationCreateThingsToDo,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Things to Do',
					pagePrefix: 'Category',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Things to Do Category</Text>
			</Col>

			<ThingsToDoCard
				onSave={async (data) => {
					const rsp = await CreateThingsToDo({
						variables: {
							name: data.name,
							hTag: data.htag,
							slug: data.slug,
							coverImage: data.coverImage,
							weight: data.weight,
							color: data.color,
							metaDescription: data.metaDescription,
							metaTitle: data.metaTitle,
							metaKeywords: data.metaKeywords,
						},
					});

					if (rsp.data) {
						CustomNotification({
							pageName: 'Things to Do',
							pagePrefix: 'Category',
							notificationType: 'created',
						});

						navigate(
							`${ROUTES.EDIT_THINGS_TO_DO.path}/${rsp.data.createEventType?.id}`,
						);
					}
				}}
			/>
		</Row>
	);
};

export default CreateThingsToDo;
