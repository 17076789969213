import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import IntroCard from 'src/components/ui/intro-video/intro-card';
import { ROUTES } from 'src/config/route';
import { IntroForm } from 'src/lib/form-interfaces';
import {
	CmsDeleteIntroVideo,
	CmsDeleteIntroVideoVariables,
} from 'src/lib/gql/generated/CmsDeleteIntroVideo';
import {
	CmsGetIntroVideo,
	CmsGetIntroVideoVariables,
} from 'src/lib/gql/generated/CmsGetIntroVideo';
import {
	CmsUpdateIntroVideo,
	CmsUpdateIntroVideoVariables,
} from 'src/lib/gql/generated/CmsUpdateIntroVideo';
import {
	MutationDeleteIntroVideo,
	MutationUpdateIntroVideo,
} from 'src/lib/gql/mutations';
import { QueryGetIntroVideo } from 'src/lib/gql/queries';

const EditIntroVideo = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetIntroVideo, CmsGetIntroVideoVariables>(
		QueryGetIntroVideo,
		{
			variables: {
				id: params.id || '', // Assign an empty string as the default value if params.id is undefined
			},
		},
	);

	const [UpdateIntroVideo] = useMutation<
		CmsUpdateIntroVideo,
		CmsUpdateIntroVideoVariables
	>(MutationUpdateIntroVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Intro Video',
				pagePrefix: 'Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(async (data: IntroForm) => {
		const rsp = await UpdateIntroVideo({
			variables: {
				id: params.id || '', // Assign an empty string as the default value if params.id is undefined
				name: data.name,
				thumbnailId: data.thumbnailId,
				videoUrl: data.videoUrl,
				dates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Intro Video',
				pagePrefix: 'Video',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	}, []);

	const [DeleteIntroVideo] = useMutation<
		CmsDeleteIntroVideo,
		CmsDeleteIntroVideoVariables
	>(MutationDeleteIntroVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Intro Video',
				pagePrefix: 'Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteQry = useCallback(
		async (id) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteIntroVideo({
				variables: {
					id: params.id || '',
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Intro Video',
					pagePrefix: 'Video',
					notificationType: 'deleted',
				});

				navigate(`${ROUTES.INTRO_VIDEO.path}`);
			}
		},
		[params.id],
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Intro Video</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				{loading ? (
					<Skeleton active />
				) : (
					<IntroCard
						introThumbnail={
							qryData?.getIntroVideo?.video?.thumbnail || undefined
						}
						introDates={
							qryData?.getIntroVideo?.introVideoDates?.map(
								(date) => date.date,
							) || []
						}
						introName={qryData?.getIntroVideo?.name || undefined}
						introVideoUrl={qryData?.getIntroVideo?.video?.url}
						canDelete={true}
						onSave={(data) => {
							updateQry({
								dates: data.dates,
								name: data.name,
								thumbnailId: data.thumbnailId,
								videoUrl: data.videoUrl,
							});
						}}
						onDelete={deleteQry}
					/>
				)}
			</Row>
		</Row>
	);
};

export default EditIntroVideo;
