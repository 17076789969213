import {
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Space, Spin, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import {
	CmsDeleteEventAlbum,
	CmsDeleteEventAlbumVariables,
} from 'src/lib/gql/generated/CmsDeleteEventAlbum';
import {
	CmsGetPaginationEventDates,
	CmsGetPaginationEventDatesVariables,
} from 'src/lib/gql/generated/CmsGetPaginationEventDates';
import { CmsGetPhotographers } from 'src/lib/gql/generated/CmsGetPhotographers';
import { MutationDeleteEventAlbum } from 'src/lib/gql/mutations';
import { QueryGetEvents, QueryGetPhotographers } from 'src/lib/gql/queries';
import {
	EventPhotographer,
	EventPhotosTable,
	EventTable,
} from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const Photos = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [eventName, setEventName] = useState<string>('');
	const [photographerId, setPhotographerId] = useState<string>('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPaginationEventDates, CmsGetPaginationEventDatesVariables>(
		QueryGetEvents,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				pageNumber: pageNumber,
				perPage: perPage,
				photographer: true,
				name: eventName,
				photographerId: photographerId,
			},
		},
	);

	const { data: qryPhotographers } = useQuery<CmsGetPhotographers>(
		QueryGetPhotographers,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const p_Events: EventPhotosTable[] = [];

	qryData?.pagination?.eventDates?.result?.forEach((event, i) => {
		return p_Events.push({
			eDate: format(parseISO(event?.eDate), 'LLL dd-yyyy'),
			eventName: event?.event?.name || '',
			id: event?.id || '-1',
			key: (i + 1).toString() || '-1',
			venueName: event?.eventParent?.venue?.name || '',
			hasAlbum: event?.hasAlbum || false,
			photographers:
				event.photographers?.map((e) => {
					return {
						adminComment: '',
						id: e.id || '',
						name: `${e.photographer?.fName} ${e.photographer?.lName}`,
						photographerComment: '',
						photographerId: e.photographer?.id || '',
					};
				}) || [],
			mediaCount: event?.eventPhotosCount?.totalPhoto || 0,
			pendingCount: event?.eventPhotosCount?.pending || 0,
		});
	});

	const [delEventAlbum] = useMutation<
		CmsDeleteEventAlbum,
		CmsDeleteEventAlbumVariables
	>(MutationDeleteEventAlbum, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Photos',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteAlbum = async (id: string) => {
		if (!confirm('Confirm delete entire album?')) return;
		const rsp = await delEventAlbum({
			variables: {
				eventDateId: id,
			},
		});
		CustomNotification({
			pageName: 'Photos',
			pagePrefix: 'Album',
			notificationType: 'deleted',
		});
		refetchQuery();
	};

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Venue',
			dataIndex: 'venueName',
			key: 'title',
			width: 200,
			filters: qryData?.pagination?.eventDates?.result
				?.map((ed) => {
					return {
						text: ed.eventParent?.venue?.name,
						value: ed.eventParent?.venue?.name,
					};
				})
				.filter(
					(ed, index, self) =>
						index ===
						self.findIndex((o) => o.text === ed.text && o.value === ed.value),
				),
			onFilter: (value, record) => record.venueName.indexOf(value) === 0,

			// ...tableColumnTextFilterConfig<EventTable>(),
			// onFilter: (value, record) => {
			// 	return record.venueName
			// 		.toString()
			// 		.toLowerCase()
			// 		.includes(value.toString().toLowerCase());
			// },
		},
		{
			title: 'Date',
			dataIndex: 'eDate',
			key: 'eDate',
			width: 110,
			align: 'center',
			sorter: (a, b) =>
				new Date(a.eDate).valueOf() - new Date(b.eDate).valueOf(),
			render: (date: string) => date,
		},
		{
			title: 'Event Name',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.eventName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Photographer',
			dataIndex: 'photographers',
			key: 'photographers',
			width: 120,
			align: 'center',
			filters: qryPhotographers?.photographers?.map((ed) => {
				return {
					text: [ed?.fName, ed?.lName].join(' '),
					value: ed?.id,
				};
			}),
			onFilter: (value, record) => {
				return record.photographers
					.map((p) => p.photographerId)
					.toString()
					.includes(value);
			},
			filterMultiple: false,
			render: (photo: EventPhotographer[]) => {
				return (
					<div
						style={{
							maxHeight: '100px',
							overflowY: 'scroll',
						}}
					>
						{photo.map((p) => {
							return <Tag key={p.id}>{p.name}</Tag>;
						})}
					</div>
				);
			},
		},
		{
			title: 'Media Count',
			dataIndex: 'mediaCount',
			key: 'mediaCount',
			width: 120,
			align: 'center',
			sorter: (a, b) => a.mediaCount.valueOf() - b.mediaCount.valueOf(),
			render: (mediaCount: string) => mediaCount,
		},
		{
			title: 'Pending Approval',
			dataIndex: 'pendingCount',
			key: 'pendingCount',
			width: 120,
			align: 'center',
			sorter: (a, b) => a.mediaCount.valueOf() - b.mediaCount.valueOf(),
			render: (mediaCount: string) => mediaCount,
		},
		{
			title: 'Edit',
			width: 80,
			align: 'center',
			render: (props: { id: string; hasAlbum: boolean }) => {
				const { id, hasAlbum } = props;
				return (
					<Space>
						<Link to={`${ROUTES.EDIT_PHOTOS.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>

						{hasAlbum && (
							<CloseCircleFilled
								onClick={() => {
									deleteAlbum(id);
								}}
								style={{
									color: 'var(--invalid)',
									width: '12px',
									height: '12px',
								}}
							/>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col
				span={24}
				style={{
					marginBottom: '2rem',
				}}
			>
				<Text className="sub-header">Photos</Text>
			</Col>

			<Col span={24}>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Events}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);

							setEventName(filters?.eventName?.toString().toLowerCase() || '');
							setPhotographerId(filters?.photographers?.toString() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.eventDates?.paginationInfo?.totalResult ||
								0,
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Photos;
