import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteVenueStory,
	CmsDeleteVenueStoryVariables,
} from 'src/lib/gql/generated/CmsDeleteVenueStory';
import {
	CmsGetPaginatedVenueStories,
	CmsGetPaginatedVenueStoriesVariables,
} from 'src/lib/gql/generated/CmsGetPaginatedVenueStories';
import {
	CmsUpdateVenueStoryMutation,
	CmsUpdateVenueStoryMutationVariables,
} from 'src/lib/gql/generated/CmsUpdateVenueStoryMutation';
import {
	MutationDeleteVenueStory,
	UpdateVenueStory,
} from 'src/lib/gql/mutations';
import { VenueStories } from 'src/lib/gql/queries';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

interface StoryTable {
	id: string;
	key: string;
	weight: number | undefined;
	venueName: string;
	tags: string[];
	enabled: boolean;
}
const Stories = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [venueName, setVenueName] = useState('');
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedVenueStories,
		CmsGetPaginatedVenueStoriesVariables
	>(VenueStories, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			venueName: venueName,
		},
	});

	const [updateVenueStory] = useMutation<
		CmsUpdateVenueStoryMutation,
		CmsUpdateVenueStoryMutationVariables
	>(UpdateVenueStory, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteVenueStory] = useMutation<
		CmsDeleteVenueStory,
		CmsDeleteVenueStoryVariables
	>(MutationDeleteVenueStory, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateStory = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
		) => {
			const rsp = await updateVenueStory({
				variables: {
					id: id,
					enabled: enabled,
					weight: pos,
				},
			});
			if (rsp.data) {
				CustomNotification({
					pageName: 'Stories',
					pagePrefix: 'Story',
					notificationType: 'updated',
				});
			}
			refetchQuery();
		},
		[qryData?.pagination?.venueStories?.result],
	);

	// const tagFilter: any = [];

	// qryData?.pagination?.venueStories?.result.map((story) => {
	// 	return story?.venueStoryHTags?.map((tag) => {
	// 		tagFilter.push({
	// 			text: tag?.hTag,
	// 			value: tag?.hTag,
	// 		});
	// 	});
	// });

	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => {
				return a.weight - b.weight;
			},

			className: 'drag-visible',
			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateStory(id, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Venue Name',
			dataIndex: 'venueName',
			key: 'name',
			width: 500,
			ellipsis: true,
			// filters: qryData?.pagination?.venueStories?.result
			// 	?.map((ed) => {
			// 		return {
			// 			text: ed?.venue?.name,
			// 			value: ed?.venue?.name,
			// 		};
			// 	})
			// 	.filter(
			// 		(ed, index, self) =>
			// 			index ===
			// 			self.findIndex((o) => o.text === ed.text && o.value === ed.value),
			// 	),
			// onFilter: (value, record) => record.venueName.indexOf(value) === 0,
			...tableColumnTextFilterConfig<StoryTable>(),
			onFilter: (value, record) => {
				return record.venueName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		// {
		// 	title: 'Appears in Pages',
		// 	key: 'tags',
		// 	width: 150,
		// 	dataIndex: 'tags',
		// 	filters: tagFilter.filter(
		// 		(slider, index, self) =>
		// 			index ===
		// 			self.findIndex(
		// 				(s) => s.text === slider.text && s.value === slider.value,
		// 			),
		// 	),
		// 	onFilter: (value, record) => record.tags.includes(value),
		// 	render: (tags: string[]) => (
		// 		<div
		// 			style={{
		// 				maxHeight: '100px',
		// 				overflowY: 'scroll',
		// 			}}
		// 		>
		// 			{tags?.map((tag) => {
		// 				let color = tag.length > 5 ? 'geekblue' : 'green';
		// 				if (tag === 'rhino') {
		// 					color = 'volcano';
		// 				}
		// 				return (
		// 					<Tag
		// 						color={color}
		// 						key={tag}
		// 						style={{
		// 							margin: '2px',
		// 							display: 'block',
		// 						}}
		// 					>
		// 						{tag.toUpperCase()}
		// 					</Tag>
		// 				);
		// 			})}
		// 		</div>
		// 	),
		// },
		{
			title: 'Edit',
			key: 'enabled',
			width: 70,
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { enabled: boolean; id: string }) => {
				const { enabled, id } = props;
				return (
					<Space
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Link to={`${ROUTES.EDIT_STORY.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateStory(id, !enabled, undefined);
							}}
							style={{
								color: enabled === true ? 'var(--valid)' : 'var(--inactive)',
							}}
						/>
						<CloseCircleFilled
							onClick={async () => {
								if (!confirm('Confirm delete?')) return;
								await deleteVenueStory({
									variables: {
										id: id,
									},
								});
							}}
							style={{
								color: 'var(--invalid)',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Stories</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					justify="end"
					align="bottom"
					style={{
						margin: '16px 0',
					}}
				>
					<Col style={{ marginRight: 'auto' }}>
						<Link to={ROUTES.CREATE_STORY.path}>
							<Button className="btn-main">
								Create New Story
								<PlusOutlined
									style={{
										position: 'relative',
										top: '1.5px',
									}}
								/>
							</Button>
						</Link>
					</Col>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<StoryTable>
						data={
							qryData?.pagination?.venueStories?.result.map((story, i) => ({
								id: story?.id || '0',
								key: (i + 1).toString() || '0',
								venueName: story?.venue?.name || '',
								weight: story?.weight || undefined,
								tags: [],
								// story?.venueStoryHTags?.map((tag) => tag?.hTag || '') || [],
								enabled: story?.enabled || false,
							})) || []
						}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
							setVenueName(filters?.name?.toString()?.toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.venueStories?.paginationInfo
									?.totalResult || 0,
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Stories;
