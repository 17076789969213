import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import InfoTable from 'src/components/info-table';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteVideoType,
	CmsDeleteVideoTypeVariables,
} from 'src/lib/gql/generated/CmsDeleteVideoType';
import {
	CmsGetVideoTypes,
	CmsGetVideoTypesVariables,
} from 'src/lib/gql/generated/CmsGetVideoTypes';
import {
	CmsUpdateVideoType,
	CmsUpdateVideoTypeVariables,
} from 'src/lib/gql/generated/CmsUpdateVideoType';
import {
	MutationDeleteVideoType,
	MutationUpdateVideoType,
} from 'src/lib/gql/mutations';
import { QueryGetVideoTypes } from 'src/lib/gql/queries';
import { EventTable, VideoCategoryTable } from 'src/lib/interface';
import { ROUTES } from '../config/route';

const VideosCategory = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVideoTypes, CmsGetVideoTypesVariables>(
		QueryGetVideoTypes,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				showAll: true,
			},
		},
	);

	const p_Videos: VideoCategoryTable[] = [];

	const p_Videos_Query = qryData?.getVideoTypes?.map((videos, i) => {
		return p_Videos.push({
			id: videos.id || '',
			key: (i + 1).toString() || '',
			name: videos?.name || '',
			weight: videos.weight || 0,
			enabled: videos.enabled || false,
		});
	});

	const [UpdateVideoCategory] = useMutation<
		CmsUpdateVideoType,
		CmsUpdateVideoTypeVariables
	>(MutationUpdateVideoType, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateType = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
		) => {
			const rsp = await UpdateVideoCategory({
				variables: {
					id: id,
					data: {
						enabled: enabled,
						weight: pos,
					},
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos Categories',
					pagePrefix: 'Video Category',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[p_Videos_Query],
	);

	const [DeleteVideoCategory] = useMutation<
		CmsDeleteVideoType,
		CmsDeleteVideoTypeVariables
	>(MutationDeleteVideoType, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteType = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteVideoCategory({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos Categories',
					pagePrefix: 'Video Category',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.getVideoTypes],
	);

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => a.weight - b.weight,
			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateType(id, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { enabled: boolean; id: string; key: string }) => {
				const { enabled, id } = props;

				return (
					<Space>
						<Link to={`${ROUTES.EDIT_VIDEOS_CATEGORY.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateType(id, !enabled, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteType(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Videos Categories</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_VIDEOS_CATEGORY.path}>
						<Button className="btn-main">
							Create New Video Category
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Videos}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default VideosCategory;
