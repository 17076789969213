import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import OfferCard from 'src/components/ui/offers/offer-card';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateOffer,
	CmsCreateOfferVariables,
} from 'src/lib/gql/generated/CmsCreateOffer';
import { MutationCreateOffer } from 'src/lib/gql/mutations';

const CreateOffers = () => {
	const [position, setPosition] = useState<number | null>(null);
	const navigate = useNavigate();

	const [createOffer] = useMutation<CmsCreateOffer, CmsCreateOfferVariables>(
		MutationCreateOffer,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Offers',
					pagePrefix: 'Offer',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	return (
		<Row>
			<Col span={24}>
				<Text
					className="sub-header"
					style={{ display: 'block', marginBottom: '0.5em' }}
				>
					Create an Offer
				</Text>
			</Col>

			{/* Event Component - Props explained in the component */}
			{/* 
			
				offerImage?: string; // Offer Image
				offerName?: string; // Offer Name
				offerDescription?: string; // Offer Description
				offerVenue?: string; // Offer Venue
				offerDates?: string[]; //Selected Dates
				canDelete?: boolean;
				onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
				onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
				
			*/}

			<OfferCard
				onSave={async (data) => {
					const rsp = await createOffer({
						variables: {
							title: data.name,
							offerDates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
							venueId: data.venueId,
							coverImageId: data.mediaId,
							description: data.description,
							weight: data.weight ? parseInt(data.weight) : undefined,
							offerTypeId: data.offerTypeId,
							offerHTags: data.tags || ['Test'],
						},
					});

					if (rsp.data) {
						CustomNotification({
							pageName: 'Offers',
							pagePrefix: 'Offer',
							notificationType: 'created',
						});
						navigate(`${ROUTES.EDIT_OFFERS.path}/${rsp.data.createOffer?.id}`);
					}
				}}
			/>
		</Row>
	);
};

export default CreateOffers;
