import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
	FlagOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteVenue,
	CmsDeleteVenueVariables,
} from 'src/lib/gql/generated/CmsDeleteVenue';
import {
	CmsGetVenues,
	CmsGetVenuesVariables,
} from 'src/lib/gql/generated/CmsGetVenues';
import {
	CmsUpdateVenue,
	CmsUpdateVenueVariables,
} from 'src/lib/gql/generated/CmsUpdateVenue';
import {
	MutationDeleteVenue,
	MutationUpdateVenue,
} from 'src/lib/gql/mutations';
import { QueryGetVenues } from 'src/lib/gql/queries';
import { VenuesTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const Venues = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [venueName, setVenueName] = useState<string>('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenues, CmsGetVenuesVariables>(QueryGetVenues, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			name: venueName,
		},
	});

	const [updateVenue] = useMutation<CmsUpdateVenue, CmsUpdateVenueVariables>(
		MutationUpdateVenue,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const updateV = async (
		id: string,
		enabled: boolean | undefined,
		weight: number | null | undefined,
	) => {
		const rsp = await updateVenue({
			variables: {
				id: id,
				enabled: enabled,
				weight: weight,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Venues',
				pagePrefix: 'Venue',
				notificationType: 'updated',
			});
			refetchQuery();
		}
	};

	const [deleteVenue] = useMutation<CmsDeleteVenue, CmsDeleteVenueVariables>(
		MutationDeleteVenue,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const deleteV = async (id: string) => {
		if (!confirm('Confirm delete?')) return;
		const rsp = await deleteVenue({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Venues',
				pagePrefix: 'Venue',
				notificationType: 'deleted',
			});

			refetchQuery();
		}
	};

	const p_Venues: VenuesTable[] = [];

	const p_Venues_Query = qryData?.pagination?.venues?.result?.forEach(
		(venue, i) => {
			return p_Venues.push({
				id: venue?.id || '-1',
				key: (i + 1).toString() || '',
				name: venue?.name || '',
				enabled: venue?.enabled || false,
				weight: venue?.weight || undefined,
				status: venue?.status || undefined,
			});
		},
	);

	// p_Venues.sort((a, b) => parseInt(a.key) - parseInt(b.key));

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => a.weight - b.weight,

			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateV(id, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Venue Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<VenuesTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Venue Status',
			dataIndex: 'status',
			key: 'status',
			width: 200,
			// ...tableColumnTextFilterConfig<VenuesTable>(),
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { id: string; enabled: boolean }) => {
				const { id, enabled } = props;
				return (
					<Space>
						<Link to={`${ROUTES.EDIT_VENUE.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateV(id, !enabled, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteV(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
						<Link to={`${ROUTES.REPORT_VENUE.path}/${id}`}>
							<FlagOutlined
								style={{
									color: 'var(--invalid)',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Venues</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_VENUE.path}>
						<Button className="btn-main">
							Create New Venue
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Venues}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
							setVenueName(filters?.name?.toString().toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.venues?.paginationInfo?.totalResult || 0,
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Venues;
