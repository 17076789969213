import {
	AimOutlined,
	AppstoreOutlined,
	CalendarOutlined,
	CameraOutlined,
	ColumnWidthOutlined,
	CopyOutlined,
	HomeOutlined,
	LogoutOutlined,
	PictureOutlined,
	PlayCircleOutlined,
	SelectOutlined,
	StarOutlined,
	TagsOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Menu, Select } from 'antd';
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logoutUser } from 'src/lib/firebase/fbClient';
import { CmsGetRegions } from 'src/lib/gql/generated/CmsGetRegions';
import { Role } from 'src/lib/gql/generated/globalTypes';
import { QueryGetRegions } from 'src/lib/gql/queries';
import { ROUTES } from '../../src/config/route';
import logo from '../logo.svg';

const { Option } = Select;

interface Props {
	isPhotographer?: boolean;
}

const Navbar: React.FunctionComponent<Props> = ({ isPhotographer }: Props) => {
	const location = useLocation();
	//const [, setUserToken] = useRecoilState(userTokenAtom);

	const user = JSON.parse(localStorage.getItem('authUser') as string);

	const navigate = useNavigate();
	const fnLogout = async () => {
		await logoutUser();
		window.location.href = '/';
	};

	const { data: qryData } = useQuery<CmsGetRegions>(QueryGetRegions);

	const photographerMenuItems = [
		{
			key: 'photographer',
			icon: <HomeOutlined />,
			label: <Link to={ROUTES.PHOTOGRAPHER_HOME.path}>Events Calendar</Link>,
		},
		{
			key: 'events/assign',
			icon: <CameraOutlined />,
			label: (
				<Link to={ROUTES.PHOTOGRAPHER_EVENTSELFASSIGN.path}>
					Assign to Events
				</Link>
			),
		},
	];

	const adminMenuItems = [
		{
			key: 'intro-video',
			icon: <HomeOutlined />,
			label: <Link to={ROUTES.INTRO_VIDEO.path}>Intro Video</Link>,
		},
		{
			key: 'stories',
			icon: <PictureOutlined />,
			label: <Link to={ROUTES.STORIES.path}>Story</Link>,
		},
		{
			key: 'slider',
			icon: <ColumnWidthOutlined />,
			label: <Link to={ROUTES.SLIDERS.path}>Slider</Link>,
		},
		{
			key: 'news',
			icon: <CopyOutlined />,
			label: 'News / Articles',
			children: [
				{
					key: 'news-list',
					label: <Link to={ROUTES.NEWS.path}>List of Articles</Link>,
				},
				{
					key: 'news-comments',
					label: <Link to={ROUTES.NEWS_COMMENTS.path}>Comments</Link>,
				},
				{
					key: 'news-categories',
					label: <Link to={ROUTES.NEWS_CATEGORIES.path}>News Categories</Link>,
				},
				{
					key: 'news-featuredlist',
					label: <Link to={ROUTES.EDIT_FEATURED_LIST.path}>Featured List</Link>,
				},
			],
		},
		{
			key: 'things-to-do',
			icon: <AppstoreOutlined />,
			label: <Link to={ROUTES.THINGS_TO_DO.path}>Things to Do</Link>,
		},
		{
			key: 'events',
			icon: <CalendarOutlined />,
			label: 'Events',
			children: [
				{
					key: 'events-calendar',
					label: <Link to={ROUTES.EVENTS.path}>Event Calendar</Link>,
				},
				{
					key: 'events-pending',
					label: (
						<Link to={ROUTES.VIEW_PENDING_EVENTS.path}>Event Activation</Link>
					),
				},
				{
					key: 'events-photographers',
					label: (
						<Link to={ROUTES.EVENT_PHOTOGRAPHER.path}>Event Photographer</Link>
					),
				},
				{
					key: 'events-genres',
					label: <Link to={ROUTES.EVENTS_GENRES.path}>Events Genres</Link>,
				},
			],
		},
		{
			key: 'best-places',
			icon: <StarOutlined />,
			label: <Link to={ROUTES.BEST_PLACES.path}>Best Places in Town</Link>,
		},
		{
			key: 'discover-the-city',
			icon: <SelectOutlined />,
			label: <Link to={ROUTES.DISCOVER_CITY.path}>Discover the City</Link>,
		},
		{
			key: 'venues',
			icon: <AimOutlined />,
			label: <Link to={ROUTES.VENUES.path}>Venues</Link>,
		},
		{
			key: 'photos',
			icon: <PictureOutlined />,
			label: <Link to={ROUTES.PHOTOS.path}>Photos</Link>,
		},
		{
			key: 'videos',
			icon: <PlayCircleOutlined />,
			label: 'Videos',
			children: [
				{
					key: 'videos-all',
					label: <Link to={ROUTES.VIDEOS.path}>All Videos</Link>,
				},
				{
					key: 'videos-categories',
					label: (
						<Link to={ROUTES.VIDEOS_CATEGORY.path}>Videos Categories</Link>
					),
				},
			],
		},
		{
			key: 'offers',
			icon: <TagsOutlined />,
			label: 'Offers',
			children: [
				{
					key: 'offers-all',
					label: <Link to={ROUTES.OFFERS.path}>Offers</Link>,
				},
				{
					key: 'offers-categories',
					label: (
						<Link to={ROUTES.OFFERS_CATEGORIES.path}>Offers Categories</Link>
					),
				},
				{
					key: 'offers-activation',
					label: (
						<Link to={ROUTES.VIEW_PENDING_OFFERS.path}>Offer requests</Link>
					),
				},
			],
		},
		{
			key: 'popup-ad',
			icon: <HomeOutlined />,
			label: <Link to={ROUTES.POPUP_AD.path}>Popup Ad</Link>,
		},
		{
			key: 'users',
			icon: <UserOutlined />,
			label: 'Users',
			children: [
				{
					key: 'users-all',
					label:
						user?.type === Role.SUPER_ADMIN ? (
							<Link to={ROUTES.USERS.path}>All Users</Link>
						) : null,
				},
				{
					key: 'users-photographers',
					label: <Link to={ROUTES.PHOTOGRAPHERS.path}>Photographers</Link>,
				},
				{
					key: 'users-photographers-requests',
					label: (
						<Link to={ROUTES.PHOTOGRAPHERS_REQUESTS.path}>
							Photographers Requests
						</Link>
					),
				},
			].filter((item) => item.label !== null),
		},
	];

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '24px 0 16px 0',
					padding: '0 10px',
				}}
			>
				<img
					src={`${logo}`}
					alt="Dubai Night"
					style={{
						maxWidth: '150px',
						width: '100%',
					}}
				/>
				<div
					style={{
						width: '100%',
						marginTop: '1.15rem',
					}}
				>
					<Select
						placeholder="Select Region"
						style={{
							width: '100%',
						}}
						onChange={(e) => {
							window.open(`https://${e}`, '_blank');
						}}
					>
						{qryData?.getRegions?.map((regions, i) => (
							<Option key={'Region' + i} value={regions.cmsDomainName || ''}>
								{regions.name}
							</Option>
						))}
					</Select>
				</div>

				<Button
					onClick={fnLogout}
					key="logoutAd"
					icon={<LogoutOutlined style={{ color: 'white' }} />}
					style={{
						color: 'white',
						backgroundColor: 'var(--invalid)',
						borderRadius: '5px',
						marginTop: '1rem',
						width: '100%',
						maxWidth: '50%',
					}}
				>
					Logout
				</Button>
			</div>

			<Menu
				defaultSelectedKeys={[location.pathname.replace('/', '')]}
				selectedKeys={[location.pathname.replace('/', '')]}
				mode="inline"
				theme="light"
				items={
					user?.type === Role.PHOTOGRAPHER
						? photographerMenuItems
						: adminMenuItems
				}
			/>
		</div>
	);
};

export default Navbar;
