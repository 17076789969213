import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsDeleteVenue,
	CmsDeleteVenueVariables,
} from 'src/lib/gql/generated/CmsDeleteVenue';
import {
	CmsGetVenue,
	CmsGetVenueVariables,
} from 'src/lib/gql/generated/CmsGetVenue';
import {
	CmsUpdateVenue,
	CmsUpdateVenueVariables,
} from 'src/lib/gql/generated/CmsUpdateVenue';
import { MediaType } from 'src/lib/gql/generated/globalTypes';
import {
	MutationDeleteVenue,
	MutationUpdateVenue,
} from 'src/lib/gql/mutations';
import { QueryGetVenue } from 'src/lib/gql/queries';
import VenueCard, {
	VenueCardSaveData,
} from '../../components/ui/venues/venue-card';

interface Params {
	id: string;
}

const EditVenue = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenue, CmsGetVenueVariables>(QueryGetVenue, {
		variables: {
			id: params.id || '',
		},
		fetchPolicy: 'cache-and-network',
	});

	const [updateVenue] = useMutation<CmsUpdateVenue, CmsUpdateVenueVariables>(
		MutationUpdateVenue,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const updateQry = useCallback(
		async (data: VenueCardSaveData) => {
			const rsp = await updateVenue({
				variables: {
					id: qryData?.getVenue?.id || '-1',
					hTag: data.htag,
					dressCode: data.dressCode,
					meta: data.meta,
					map: data.map,
					coverImageId: data.coverImageId,
					mediaIds: data.mediaIds,
					menuIds: data.menuIds,
					logoId: data.logoId,
					info: data.info,
					partner: data.partner,
					slug: data.slug,
					areaId: data.areaId,
					ctaNumber: data.ctaNumber,
					ctaLink: data.ctaLink,
					longitude: data.longitude,
					latitude: data.latitude,
					venueTypeIds: data.venueTypeIds,
					dateTiming: data.dateTiming,
					weight: data.numberWight,
					famousFor: data.famousFor,
					name: data.name,
					verified: data.verified,
					virtualTour: data.virtualTour,
					status: data.status,
					properName: data.properName,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getVenue?.id],
	);

	const cMeadia = useMemo(() => {
		return qryData?.getVenue?.coverImage
			? {
					id: qryData?.getVenue?.coverImage?.id,
					url: qryData?.getVenue?.coverImage?.url || '',
					type: MediaType.IMAGE,
			  }
			: undefined;
	}, [qryData?.getVenue?.coverImage?.id]);

	const [deleteVenue] = useMutation<CmsDeleteVenue, CmsDeleteVenueVariables>(
		MutationDeleteVenue,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const fnDeleteVenue = useCallback(
		async (data) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await deleteVenue({
				variables: {
					id: qryData?.getVenue?.id || '-1',
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Venues',
					pagePrefix: 'Venue',
					notificationType: 'deleted',
				});

				refetchQuery();
				navigate(`${ROUTES.VENUES.path}`);
			}
		},
		[qryData?.getVenue?.id],
	);
	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Edit Venue
				</Text>
			</Col>

			{/* Venue Component - Props explained in the component */}
			{/* 
		venueName?: string; // Event Name
		venueSlug?: string; //Optional - Link for story
		venueType?: string; // Select Event Type
		venueInfo?: string; // Event Information and Description
		venueFamousFor?: string;
		venueLocation?: string; // How to get there - Google Map URL
		venueLocationLongitude?: string; // How to get there - Google Map URL
		venueLocationLatitude?: string; // How to get there - Google Map URL
		venueBookingURL?: string; // CTA Links -> Booking URL
		venueContactNumber?: string; // CTA Links -> Contact Number
		venueLogo?: string; // Event Poster / Banner
		venueImages?: string; // Event Video Thumbnail
		canDelete?: boolean;
		onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
		onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
			*/}
			{qryLoading ? (
				<Skeleton active />
			) : (
				<VenueCard
					onSave={updateQry}
					venueName={qryData?.getVenue?.name || ''}
					properName={qryData?.getVenue?.properName || ''}
					venueBookingURL={qryData?.getVenue?.ctaLink || null}
					venueContactNumber={qryData?.getVenue?.ctaNumber || null}
					venueDateTiming={qryData?.getVenue?.details?.dateTiming || ''}
					venueDressCode={qryData?.getVenue?.details?.dressCode || ''}
					venueLocation={qryData?.getVenue?.details?.map || ''}
					venueLocationLatitude={qryData?.getVenue?.latitude || ''}
					venueLocationLongitude={qryData?.getVenue?.longitude || ''}
					venueLogo={qryData?.getVenue?.logo || undefined}
					venueSlug={qryData?.getVenue?.slug || ''}
					venueImages={qryData?.getVenue?.venuePhotos || undefined}
					venueMenuImages={qryData?.getVenue?.venueMenus || undefined}
					venueTag={qryData?.getVenue?.hTag || ''}
					venueInfo={qryData?.getVenue?.details?.info || ''}
					venueMeta={qryData?.getVenue?.meta || ''}
					venuePartner={qryData?.getVenue?.partner || false}
					coverImage={qryData?.getVenue?.coverImage || undefined}
					venueArea={qryData?.getVenue?.area?.id}
					venueTypes={qryData?.getVenue?.venueTypes?.map((type) => {
						return type.id;
					})}
					weight={qryData?.getVenue?.weight || undefined}
					canDelete={true}
					onDelete={fnDeleteVenue}
					verified={
						qryData?.getVenue?.verified === null
							? undefined
							: qryData?.getVenue?.verified
					}
					venueFamousFor={qryData?.getVenue?.details?.famousFor || undefined}
					venueMap={qryData?.getVenue?.details?.map || undefined}
					venueVirtualTour={
						qryData?.getVenue?.details?.virtualTour || undefined
					}
					status={qryData?.getVenue?.status || undefined}
				/>
			)}
		</Row>
	);
};

export default EditVenue;
