import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Divider, Row, Spin, Tooltip } from 'antd';
import { Comment } from '@ant-design/compatible';
import Avatar from 'antd/lib/avatar/avatar';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import {
	CmsDeleteArticleComment,
	CmsDeleteArticleCommentVariables,
} from 'src/lib/gql/generated/CmsDeleteArticleComment';
import {
	CmsGetPaginatedArticlesWithComments,
	CmsGetPaginatedArticlesWithCommentsVariables,
} from 'src/lib/gql/generated/CmsGetPaginatedArticlesWithComments';
import { DeleteArticleCommentMutation } from 'src/lib/gql/mutations';
import { QueryGetArticlesWithComments } from 'src/lib/gql/queries';
import { ArticlesCommentTable, ArticlesTable } from 'src/lib/interface';
import InfoTable from '../../components/info-table';

const NewsComments = () => {
	const [action] = useState('');

	const actions = [
		// <Tooltip key="comment-basic-like" title="Likes">
		// 	<span>
		// 		{createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
		// 		<span className="comment-action">0</span>
		// 	</span>
		// </Tooltip>,
	];

	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedArticlesWithComments,
		CmsGetPaginatedArticlesWithCommentsVariables
	>(QueryGetArticlesWithComments, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
		},
		notifyOnNetworkStatusChange: true,
	});

	const [deleteArticleCommentMutation, { loading: mutationLoading }] =
		useMutation<CmsDeleteArticleComment, CmsDeleteArticleCommentVariables>(
			DeleteArticleCommentMutation,
			{
				onError(error) {
					CustomNotification({
						pageName: 'Comments',
						pagePrefix: 'Comment',
						notificationType: 'custom-error',
						customDescription: error.message,
					});
				},
			},
		);

	const DeleteBtn = ({ commentId }: { commentId: string }) => {
		return (
			<Tooltip key="comment-basic-delete" title="Delete Comment">
				<span
					key="comment-basic-reply-to"
					style={{
						color: 'var(--invalid)',
					}}
					onClick={async () => {
						if (!mutationLoading) {
							if (!confirm('Confirm delete?')) return;
							deleteArticleCommentMutation({
								variables: {
									id: commentId,
								},
							}).then((rsp) => {
								if (rsp.data) {
									CustomNotification({
										pageName: 'Comments',
										pagePrefix: 'Comment',
										notificationType: 'deleted',
									});
									refetchQuery();
								}
							});
						}
					}}
				>
					Delete
				</span>
			</Tooltip>
		);
	};

	const p_Articles: ArticlesCommentTable[] = [];

	qryData?.pagination?.articles?.result?.forEach((article, i) => {
		if ((article.comments?.length || 0) > 0)
			p_Articles.push({
				id: article?.id || '-1',
				key: (i + 1).toString() || '',
				date: format(parseISO(article?.date), 'LLL dd-yyyy'),
				tags: [],
				name: article?.title || '',
				weight: article?.weight || 1,
				enabled: article.enabled || false,
				featured: article.featured || false,
				slug: article.slug || '',
				comments:
					article.comments?.map((comment) => {
						return {
							comment: comment.comment || '',
							id: comment.id || '',
							date: format(parseISO(comment.date), 'dd-MM-yyyy hh:m:s aaa'),
							userId: comment.user?.id || '',
							userName: `${comment.user?.fName} ${comment.user?.lName}`,
							replies:
								comment.replies?.map((replay) => {
									return {
										comment: replay.comment || '',
										id: replay.id || '',
										date: format(
											parseISO(replay.date),
											'dd-MM-yyyy hh:m:s aaa',
										),
										userId: replay.user?.id || '',
										userName: `${replay.user?.fName} ${replay.user?.lName}`,
									};
								}) || [],
						};
					}) || [],
			});
	});

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},

		{
			title: 'Title',
			dataIndex: 'name',
			key: 'title',
			width: 200,
			ellipsis: true,
			...tableColumnTextFilterConfig<ArticlesTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: 100,
			align: 'center',
			sorter: (a, b) =>
				new Date(a.dsate).valueOf() - new Date(b.date).valueOf(),
			render: (date: string) => date,
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Articles Comments</Text>
			</Col>

			<Col
				span={24}
				style={{
					margin: '16px 0',
				}}
			>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<ArticlesTable>
						data={p_Articles}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
						}}
						pagination={{
							total:
								qryData?.pagination?.articles?.paginationInfo?.totalResult || 0,
						}}
						expandable={{
							expandedRowRender: (article: ArticlesCommentTable) => {
								return (
									<React.Fragment>
										<p className="sub-header margin-0">Comments</p>

										{article.comments.map((comment) => {
											return (
												<React.Fragment key={comment.id}>
													<Comment
														actions={[
															<DeleteBtn
																commentId={comment.id || ''}
																key={'btn' + comment.id}
															/>,
														]}
														author={comment.userName}
														datetime={comment.date}
														content={<p>{comment.comment}</p>}
														avatar={<Avatar src="" />}
													/>

													<div>
														{comment?.replies?.length !== 0 && (
															<p
																className="margin-0 sub-header"
																style={{
																	fontSize: '1rem',
																}}
															>
																Replies
															</p>
														)}

														<div
															style={{
																marginLeft: '2.5rem',
															}}
														>
															{comment.replies?.map((replay) => {
																return (
																	<Comment
																		key={replay.id}
																		actions={[
																			<DeleteBtn
																				commentId={replay.id || ''}
																				key={'btn' + replay.id}
																			/>,
																		]}
																		datetime={replay.date}
																		author={replay.userName}
																		content={<p>{replay.comment}</p>}
																		avatar={<Avatar src="" />}
																	/>
																);
															})}
														</div>
													</div>
													<Divider
														style={{
															margin: '0 0 1rem 0',
														}}
													/>
												</React.Fragment>
											);
										})}
									</React.Fragment>
								);
							},
							rowExpandable: (record) => {
								return record.comments.length > 0;
							},
						}}
						other={{
							scroll: {
								x: 500,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default NewsComments;
