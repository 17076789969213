import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCategoryCard from 'src/components/ui/videos/video-category-card';
import { ROUTES } from 'src/config/route';
import { CategoryForm } from 'src/lib/form-interfaces';
import {
	CmsCreateVideoType,
	CmsCreateVideoTypeVariables,
} from 'src/lib/gql/generated/CmsCreateVideoType';
import { MutationCreateVideoType } from 'src/lib/gql/mutations';

const CreateVideosCategory = () => {
	const navigate = useNavigate();

	const [CreateCategory] = useMutation<
		CmsCreateVideoType,
		CmsCreateVideoTypeVariables
	>(MutationCreateVideoType, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: CategoryForm) => {
		const rsp = await CreateCategory({
			variables: {
				data: {
					name: data.name,
					weight: data.weight ? parseInt(data.weight.toString()) : null,
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'created',
			});

			navigate(
				`${ROUTES.EDIT_VIDEOS_CATEGORY.path}/${rsp.data.createVideoType?.id}`,
			);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Video Category</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				<VideoCategoryCard onSave={createQry} />
			</Row>
		</Row>
	);
};

export default CreateVideosCategory;
