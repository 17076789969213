import { useMutation, useQuery } from '@apollo/client';
import { Col, notification, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsDeleteSliderMutation,
	CmsDeleteSliderMutationVariables,
} from 'src/lib/gql/generated/CmsDeleteSliderMutation';
import {
	CmsGetSlider,
	CmsGetSliderVariables,
} from 'src/lib/gql/generated/CmsGetSlider';
import {
	CmsUpdateSliderMutation,
	CmsUpdateSliderMutationVariables,
} from 'src/lib/gql/generated/CmsUpdateSliderMutation';
import { MediaType } from 'src/lib/gql/generated/globalTypes';
import { DeleteSlider, UpdateSlider } from 'src/lib/gql/mutations';
import { QuerySlider } from 'src/lib/gql/queries';
import SliderCard from '../../components/ui/sliders/slider-card';
interface Params {
	id: string;
}
const EditSlider = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}
	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetSlider, CmsGetSliderVariables>(QuerySlider, {
		variables: {
			id: params.id ?? '',
		},
		fetchPolicy: 'cache-and-network',
	});

	const [updateSlider] = useMutation<
		CmsUpdateSliderMutation,
		CmsUpdateSliderMutationVariables
	>(UpdateSlider, {
		onError(error) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteSlider] = useMutation<
		CmsDeleteSliderMutation,
		CmsDeleteSliderMutationVariables
	>(DeleteSlider, {
		onError(error) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Slider</Text>
			</Col>
			{qryLoading ? (
				<Skeleton active />
			) : (
				<SliderCard
					sliderName={qryData?.getSlider?.title || ''}
					sliderPosition={qryData?.getSlider?.weight || undefined}
					sliderLink={qryData?.getSlider?.link || ''}
					canDelete={true}
					sliderDates={
						qryData?.getSlider?.sliderDates?.map((date) => date?.date || '') ||
						[]
					}
					sliderTags={qryData?.getSlider?.sliderHTags?.map(
						(tag) => tag?.hTag || '',
					)}
					sliderMedia={{
						id: qryData?.getSlider?.media?.id || '-1',
						url: qryData?.getSlider?.media?.url || '-1',
						type: qryData?.getSlider?.media?.type || MediaType.IMAGE,
						...(qryData?.getSlider?.media?.thumbnail && {
							thumbnail: {
								id: qryData?.getSlider?.media?.thumbnail.id || '-1',
								url: qryData?.getSlider?.media?.thumbnail.url || '',
							},
						}),
					}}
					onSave={async (data) => {
						notification['info']({
							message: 'Sliders',
							description: 'Slider is updating...',
							duration: 2,
						});
						const rsp = await updateSlider({
							variables: {
								sliderDates:
									data.dates.map((date) => format(date, 'yyyy-MM-dd')) || [],
								sliderHTags: data.tags || [],
								mediaId: data.mediaId,
								weight: data.position,
								link: data.link,
								mediaType: data.mediaType,
								title: data.name || '',
								videoLink: data.videoLink,
								id: params.id ?? '', // Provide a default value using the nullish coalescing operator
							},
						});
						if (rsp.data) {
							CustomNotification({
								pageName: 'Sliders',
								pagePrefix: 'Slider',
								notificationType: 'updated',
							});
						}
						refetchQuery();
					}}
					onDelete={async () => {
						if (!confirm('Are you sure you want to delete?')) return;

						const rsp = await deleteSlider({
							variables: {
								id: params.id ?? '', // Provide a default value using the nullish coalescing operator
							},
						});

						if (rsp.data) {
							CustomNotification({
								pageName: 'Sliders',
								pagePrefix: 'Slider',
								notificationType: 'deleted',
							});
						}

						navigate(`${ROUTES.SLIDERS.path}`);
					}}
				/>
			)}
		</Row>
	);
};

export default EditSlider;
