import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import InfoTable from 'src/components/info-table';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteVenueVideo,
	CmsDeleteVenueVideoVariables,
} from 'src/lib/gql/generated/CmsDeleteVenueVideo';
import { CmsGetVenueVideos } from 'src/lib/gql/generated/CmsGetVenueVideos';
import {
	CmsUpdateVenueVideo,
	CmsUpdateVenueVideoVariables,
} from 'src/lib/gql/generated/CmsUpdateVenueVideo';
import {
	MutationDeleteVenueVideo,
	MutationUpdateVenueVideo,
} from 'src/lib/gql/mutations';
import { QueryGetVenueVideos } from 'src/lib/gql/queries';
import { EventTable, VenueVideosTable } from 'src/lib/interface';
import { ROUTES } from '../config/route';

const Videos = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenueVideos>(QueryGetVenueVideos, {
		fetchPolicy: 'cache-and-network',
	});

	const p_Videos: VenueVideosTable[] = [];

	const p_Videos_Query = qryData?.getVenueVideos?.map((videos, i) => {
		return p_Videos.push({
			id: videos.id || '',
			key: (i + 1).toString() || '',
			name: videos?.name || '',
			venueName: videos.venue?.name || '',
			videoUrl: videos.video?.url || '',
			weight: videos.weight || 0,
			enabled: videos.enabled || false,
		});
	});

	const [UpdateVideo] = useMutation<
		CmsUpdateVenueVideo,
		CmsUpdateVenueVideoVariables
	>(MutationUpdateVenueVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos',
				pagePrefix: 'Venue Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateVideo = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
		) => {
			const rsp = await UpdateVideo({
				variables: {
					id: id,
					updateVenueVideoInput: {
						enabled: enabled,
						weight: pos,
					},
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos',
					pagePrefix: 'Venue Video',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[p_Videos_Query],
	);

	const [DeleteVenueVideo] = useMutation<
		CmsDeleteVenueVideo,
		CmsDeleteVenueVideoVariables
	>(MutationDeleteVenueVideo, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos',
				pagePrefix: 'Venue Video',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteVideo = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteVenueVideo({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos',
					pagePrefix: 'Venue Video',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.getVenueVideos],
	);

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => a.weight - b.weight,
			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateVideo(id, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Venue',
			dataIndex: 'venueName',
			key: 'title',
			width: 200,
			filters: qryData?.getVenueVideos
				?.map((vv) => {
					return {
						text: vv.venue?.name,
						value: vv.venue?.name,
					};
				})
				.filter(
					(vv, index, self) =>
						index ===
						self.findIndex((o) => o.text === vv.text && o.value === vv.value),
				),
			onFilter: (value, record) => record.venueName.indexOf(value) === 0,

			// ...tableColumnTextFilterConfig<EventTable>(),
			// onFilter: (value, record) => {
			// 	return record.venueName
			// 		.toString()
			// 		.toLowerCase()
			// 		.includes(value.toString().toLowerCase());
			// },
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: { enabled: boolean; id: string; key: string }) => {
				const { enabled, id } = props;

				return (
					<Space>
						<Link to={`${ROUTES.EDIT_VIDEOS.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateVideo(id, !enabled, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteVideo(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Videos</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_VIDEOS.path}>
						<Button className="btn-main">
							Create New Venue Video
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Videos}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Videos;
