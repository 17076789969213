import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateEvent as CreateEventMutation,
	CmsCreateEventVariables,
} from 'src/lib/gql/generated/CmsCreateEvent';
import { MutationCreateEvent } from 'src/lib/gql/mutations';
import CreateEventCard from '../../components/ui/events/create-event-card';

const CreateEvent = () => {
	const navigate = useNavigate();

	const [createEventFunc] = useMutation<
		CreateEventMutation,
		CmsCreateEventVariables
	>(MutationCreateEvent, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Create an Event
				</Text>
			</Col>
			<CreateEventCard
				onSave={async (data) => {
					const rsp = await createEventFunc({
						variables: {
							eventInput: {
								description: data.description,
								name: data.name,
								posterId: data.posterId || null,
								ctaLink: data.ctaLink,
								ctaNumber: data.ctaNumber,
								offers: data.offers,
								videoUrl: data.videoUrl,
								eventDateInput: {
									eventDates: data.eventDates.map((date) =>
										format(date, 'yyyy-MM-dd'),
									),
									featured: data.featured,
									photographer: data.photographer,
								},
								eventParentInput: {
									eventTypeIds: data.eventTypeIds,
									genreIds: data.genreId,
									hTag: data.hTag,
									occurrenceTime: data.occurrenceTime,
									parentName: data.parentName,
									slug: data.slug,
									venueId: data.venueId,
									meta: data.meta,
								},
							},
						},
					});

					if (rsp.data) {
						CustomNotification({
							pageName: 'Events',
							pagePrefix: 'Event',
							notificationType: 'created',
						});

						navigate(
							`${ROUTES.EDIT_EVENT.path}/${rsp.data.createEvent?.eventDates?.[0].id}`,
						);
					}
				}}
			/>
		</Row>
	);
};

export default CreateEvent;
