import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import OfferCard from 'src/components/ui/offers/offer-card';
import { ROUTES } from 'src/config/route';
import {
	CmsDeleteOffer,
	CmsDeleteOfferVariables,
} from 'src/lib/gql/generated/CmsDeleteOffer';
import {
	CmsGetOffer,
	CmsGetOfferVariables,
} from 'src/lib/gql/generated/CmsGetOffer';
import {
	CmsUpdateOffer,
	CmsUpdateOfferVariables,
} from 'src/lib/gql/generated/CmsUpdateOffer';
import {
	MutationDeleteOffer,
	MutationUpdateOffer,
} from 'src/lib/gql/mutations';
import { QueryGetOffer } from 'src/lib/gql/queries';

interface Params {
	id: string;
}

const EditOffers = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetOffer, CmsGetOfferVariables>(QueryGetOffer, {
		variables: {
			id: params?.id || '-1',
		},
		fetchPolicy: 'cache-and-network',
	});

	const [updateOffer] = useMutation<CmsUpdateOffer, CmsUpdateOfferVariables>(
		MutationUpdateOffer,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Offers',
					pagePrefix: 'Offer',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const [deleteOffer] = useMutation<CmsDeleteOffer, CmsDeleteOfferVariables>(
		MutationDeleteOffer,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Offers',
					pagePrefix: 'Offer',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const delOffer = async (id: string) => {
		if (!confirm('Are you sure you want to delete?')) return;
		const rsp = await deleteOffer({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Offers',
				pagePrefix: 'Offer',
				notificationType: 'deleted',
			});

			refetchQuery();
			navigate(`${ROUTES.OFFERS.path}`);
		}
	};

	const updateQry = useCallback(
		async (data) => {
			const rsp = await updateOffer({
				variables: {
					id: qryData?.getOffer?.id || '-1',
					coverImageId: data.mediaId,
					description: data.description,
					weight: data.weight ? parseInt(data.weight) : undefined,
					offerDates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
					title: data.name || '',
					venueId: data.venueId,
					offerTypeId: data.offerTypeId,
					offerHTags: data.tags,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Offers',
					pagePrefix: 'Offer',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getOffer?.id],
	);

	const approveQry = useCallback(async () => {
		const rsp = await updateOffer({
			variables: {
				id: qryData?.getOffer?.id || '-1',
				enabled: true,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Offers',
				pagePrefix: 'Offer',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	}, [qryData?.getOffer?.id]);

	return (
		<Row>
			<Col span={24}>
				<Text
					className="sub-header"
					style={{ display: 'block', marginBottom: '0.5em' }}
				>
					Edit Offer
				</Text>
			</Col>

			{/* Event Component - Props explained in the component */}
			{/* 
			
				offerImage?: string; // Offer Image
				offerName?: string; // Offer Name
				offerDescription?: string; // Offer Description
				offerVenue?: string; // Offer Venue
				offerDates?: string[]; //Selected Dates
				canDelete?: boolean;
				onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
				onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
				
			*/}
			{loading ? (
				<Skeleton active />
			) : (
				<OfferCard
					onSave={updateQry}
					offerApproved={
						qryData?.getOffer?.approved === true ||
						qryData?.getOffer?.approved === undefined
					}
					offerImage={qryData?.getOffer?.coverImage || undefined}
					offerDescription={qryData?.getOffer?.description || ''}
					offerName={qryData?.getOffer?.title || ''}
					offerVenue={qryData?.getOffer?.venue?.id || ''}
					offerPosition={qryData?.getOffer?.weight || undefined}
					offerDates={
						qryData?.getOffer?.offerDates?.map((date) => date.date) || []
					}
					offerTags={qryData?.getOffer?.offerHTags?.map(
						(tag) => tag.hTag || '',
					)}
					offerTypeId={qryData?.getOffer?.offerType?.id}
					canDelete={true}
					onDelete={() => {
						delOffer(qryData?.getOffer?.id || '-1');
					}}
					offerSourceName={
						(qryData?.getOffer?.offerSource?.fName || '') +
						' ' +
						(qryData?.getOffer?.offerSource?.lName || '')
					}
					offerSourceEmail={qryData?.getOffer?.offerSource?.email || ''}
					offerSourcePhone={qryData?.getOffer?.offerSource?.phone || ''}
					onApprove={approveQry}
				/>
			)}
		</Row>
	);
};

export default EditOffers;
