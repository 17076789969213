import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { useCallback, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import MediaUpload from 'src/components/ui/media-upload';
import MultiDateSelector from 'src/components/ui/multi-date';
import { PopupAdForm } from 'src/lib/form-interfaces';
import { Media, MediaFoldersNames } from 'src/lib/interface';

interface Props {
	popupName?: string;
	media?: Media;
	popupAdDates?: string[];
	canDelete?: boolean;
	onDelete?: React.MouseEventHandler<HTMLElement>;
	onSave: ({ name, mediaId, dates }: PopupAdForm) => void;
}

const PopupAdCard = (props: Props) => {
	const [mediaId, setMediaId] = useState<Media | undefined>(props.media);

	const selectedDays = useRef<Date[]>([]);

	const [form] = useForm<PopupAdForm>();

	const createPopupAd = (data: PopupAdForm) => {
		if (mediaId === null || mediaId === undefined) {
			CustomNotification({
				pageName: 'Popup Ads',
				errorName: 'Media File',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			dates: selectedDays.current,
			mediaId: mediaId.id,
			btnText: data.btnText,
			mediaLink: data.mediaLink,
		});
	};

	const mediaCallback = useCallback((media: Media) => {
		setMediaId(media);
	}, []);

	return (
		<Form
			form={form}
			onFinish={createPopupAd}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24} xl={12}>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={mediaCallback}
						media={props.media || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop a image'}
						height={'100%'}
					/>
				</Col>

				<Col span={24} xl={12}>
					<label className="input-fields-label">Name / Title</label>

					<Form.Item
						name="name"
						initialValue={props.popupName}
						rules={[{ required: true }]}
						hasFeedback
					>
						<Input
							type="text"
							placeholder="Name / Title"
							className="input-fields"
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>

					<label className="input-fields-label">Link</label>

					<Form.Item
						name="mediaLink"
						initialValue={props.popupName}
						hasFeedback
					>
						<Input
							type="text"
							placeholder="Link"
							className="input-fields"
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>
					<label className="input-fields-label">Button</label>

					<Form.Item
						name="btnText"
						style={{
							width: '100%',
						}}
					>
						<Input
							type="text"
							placeholder="Button text"
							className="input-fields"
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>

					<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
						Dates
					</Text>
					<Text style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}>
						You can click on multiple dates to select the days to run the ad for
						that duration
					</Text>

					<Form.Item name="dates">
						<MultiDateSelector
							initialDates={props.popupAdDates || []}
							onInit={(dates) => {
								selectedDays.current = dates;
							}}
							onChange={(dates) => {
								selectedDays.current = dates;
								// console.log(dates);
							}}
						/>
					</Form.Item>

					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType={'submit'}>
								Save
							</Button>

							{!props.canDelete ? (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							) : (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default PopupAdCard;
