import axios, { AxiosResponse, AxiosProgressEvent } from 'axios';
import { getToken } from './firebase/fbClient';
import { DataRegionHostname } from './interface';

export const api = (token?: string) => {
	let crDataRegion: string | undefined =
		process.env.REACT_APP_DATA_REGION || undefined;
	const hNames = location.hostname.split('.');
	if (hNames.length == 1) {
		crDataRegion = DataRegionHostname[hNames[0]];
	} else if (hNames.length > 1) {
		crDataRegion = DataRegionHostname[hNames[hNames.length - 2]];
	}

	return axios.create({
		baseURL: process.env.REACT_APP_REST_ENDPOINT,
		responseType: 'json',
		headers: {
			...(crDataRegion && {
				'x-source-region': crDataRegion,
			}),
			authorization: `${token}`,
		},
	});
};

export const uploadFile = async (data: {
	file: File;
	folder: string;
	watermark: boolean;
	name: string | undefined;
	onProgress?: (progressEvent: AxiosProgressEvent) => void;
}): Promise<AxiosResponse<any>> => {
	const token = await getToken();
	const _api = api(token || '');
	const formData = new FormData();
	formData.append('image', data.file);
	formData.append('watermark', data.watermark ? 'true' : 'false');
	formData.append('folder', data.folder);
	if (data.name) formData.append('name', data.name);
	return await _api.post('/mediaupload/fileUpload', formData, {
		onUploadProgress: data.onProgress,
	});
};

export const downloadFile = async (data: {
	endpoint: string;
	onProgress?: (progressEvent: AxiosProgressEvent) => void;
}): Promise<void> => {
	const token = await getToken();
	const _api = api(token || '');
	_api
		.get(data.endpoint, {
			responseType: 'blob',
			onDownloadProgress: data.onProgress,
		})
		.then((response) => {
			const filename = response.headers['x-filename'];
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename || 'user.csv');
				document.body.appendChild(link);
				link.click();
			}
		});
};
