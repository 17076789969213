import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import {
	CmsCreateStoryItemMutation,
	CmsCreateStoryItemMutationVariables,
} from 'src/lib/gql/generated/CmsCreateStoryItemMutation';

import {
	CmsDeleteStoryItemMutation,
	CmsDeleteStoryItemMutationVariables,
} from 'src/lib/gql/generated/CmsDeleteStoryItemMutation';
import {
	CmsGetVenueStory,
	CmsGetVenueStoryVariables,
} from 'src/lib/gql/generated/CmsGetVenueStory';

import {
	CmsUpdateStoryItemMutation,
	CmsUpdateStoryItemMutationVariables,
} from 'src/lib/gql/generated/CmsUpdateStoryItemMutation';
import {
	CmsUpdateVenueStoryMutation,
	CmsUpdateVenueStoryMutationVariables,
} from 'src/lib/gql/generated/CmsUpdateVenueStoryMutation';
import { MediaType } from 'src/lib/gql/generated/globalTypes';
import {
	CreateStoryItem,
	DeleteStoryItem,
	UpdateStoryItem,
	UpdateVenueStory,
} from 'src/lib/gql/mutations';
import { VenueStory } from 'src/lib/gql/queries';
import StoryCard from '../../components/ui/stories/story-card';

interface Params {
	id: string;
}

const EditStory = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}

	const params = useParams<Params>();
	const [addComKey, setAddComKey] = useState(new Date().toUTCString());
	const [render, setRender] = useState(false);

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenueStory, CmsGetVenueStoryVariables>(VenueStory, {
		variables: {
			id: params?.id || '-1',
		},
		fetchPolicy: 'cache-and-network',
	});

	const [createStoryItem] = useMutation<
		CmsCreateStoryItemMutation,
		CmsCreateStoryItemMutationVariables
	>(CreateStoryItem, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [updateStoryItem] = useMutation<
		CmsUpdateStoryItemMutation,
		CmsUpdateStoryItemMutationVariables
	>(UpdateStoryItem, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteStoryItem] = useMutation<
		CmsDeleteStoryItemMutation,
		CmsDeleteStoryItemMutationVariables
	>(DeleteStoryItem, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [updateVenueStory] = useMutation<
		CmsUpdateVenueStoryMutation,
		CmsUpdateVenueStoryMutationVariables
	>(UpdateVenueStory, {
		onError(error) {
			CustomNotification({
				pageName: 'Stories',
				pagePrefix: 'Venue Story',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	// const storyTags = useRef<string[] | null>(null);

	// const tagSelectionChange = useCallback((val: string[]) => {
	// 	storyTags.current = val;
	// }, []);

	// if (storyTags.current === null && qryData?.getVenueStory?.storyItems) {
	// 	storyTags.current = qryData?.getVenueStory?.storyItems?.map((tag) => {
	// 		return tag?.storyItemHTags?.[0]?.hTag || '';
	// 	});
	// 	setRender(!render);
	// }

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Story</Text>
			</Col>
			{qryLoading ? (
				<Skeleton active />
			) : (
				<>
					<Col span={24}>
						<Text style={{ display: 'block' }}>
							Venue:{' '}
							<span
								style={{
									color: '#1890ff',
									fontFamily: 'Avenir Black',
								}}
							>
								{qryData?.getVenueStory?.venue?.name}
							</span>
						</Text>
					</Col>
					{/* <SelectTags
						initialValues={storyTags.current || []}
						onSelectionChange={tagSelectionChange}
					/> */}
					{/* <Button
						onClick={async () => {
							const rsp = await updateVenueStory({
								variables: {
									id: qryData?.getVenueStory?.id || '-1',
									hTags: qryData?.getVenueStory?.storyItems?.map((tag) => {
										return tag?.storyItemHTags?.[0]?.hTag || '';
									}),
								},
							});
							if (rsp.data) {
								CustomNotification({
									pageName: 'Stories',
									pagePrefix: 'Story',
									notificationType: 'updated',
								});
							}
							refetchQuery();
						}}
						className="btn-main save"
					>
						Save
					</Button> */}
					{/* Story Component - Props explained in the component */}

					{qryData?.getVenueStory?.storyItems?.map((item, i) => (
						<StoryCard
							panelHeader={i + 1}
							onSave={async (data) => {
								const rsp = await updateStoryItem({
									variables: {
										mediaId: data.mediaId,
										storyDates: data.dates.map((date) =>
											format(date, 'yyyy-MM-dd'),
										),
										id: item?.id || '-1',
										link: data.link,
										weight: data.position,
										hTags: data.hTags,
									},
								});
								if (rsp.data) {
									CustomNotification({
										pageName: 'Stories',
										pagePrefix: 'Story',
										notificationType: 'updated',
									});
								}
								refetchQuery();
							}}
							type={'edit'}
							key={item?.id}
							bannerTitle={`Story ${i + 1}`}
							storyHTags={
								item?.storyItemHTags?.map((tag) => tag?.hTag || '') || []
							}
							storyMedia={
								item?.media
									? {
											id: item?.media?.id,
											url: item?.media?.url || '',
											type: item.media.type || MediaType.IMAGE,
									  }
									: undefined
							}
							storyId={item?.id}
							storyName={''}
							storyLink={item?.link || ''}
							storyPosition={item?.weight || undefined}
							storyDates={item?.storyItemDates?.map((date) => date?.date) || []}
							canDelete={true}
							onDelete={async (storyItemId) => {
								if (!confirm('Delete this item?')) return;
								if (storyItemId)
									await deleteStoryItem({
										variables: {
											id: storyItemId,
										},
									});
								refetchQuery();
							}}
						/>
					))}

					{/* Story Component - Create Story */}

					<StoryCard
						key={addComKey}
						onSave={async (data) => {
							const rsp = await createStoryItem({
								variables: {
									mediaId: data.mediaId,
									storyDates: data.dates.map((date) =>
										format(date, 'yyyy-MM-dd'),
									),
									storyId: qryData?.getVenueStory?.id || '-1',
									link: data.link,
									weight: data.position,
									hTags: data.hTags,
								},
							});
							if (rsp.data) {
								CustomNotification({
									pageName: 'Stories',
									pagePrefix: 'Story',
									notificationType: 'created',
								});
								setAddComKey(new Date().toUTCString());
							}
							refetchQuery();
						}}
						type="edit"
						bannerTitle={'Create New Story'}
					/>
				</>
			)}
		</Row>
	);
};

export default EditStory;
