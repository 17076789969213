import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCategoryCard from 'src/components/ui/videos/video-category-card';
import { ROUTES } from 'src/config/route';
import { CategoryForm } from 'src/lib/form-interfaces';
import {
	CmsDeleteArticleType,
	CmsDeleteArticleTypeVariables,
} from 'src/lib/gql/generated/CmsDeleteArticleType';
import {
	CmsGetArticleType,
	CmsGetArticleTypeVariables,
} from 'src/lib/gql/generated/CmsGetArticleType';
import {
	CmsUpdateArticleType,
	CmsUpdateArticleTypeVariables,
} from 'src/lib/gql/generated/CmsUpdateArticleType';
import {
	MutationDeleteArticleType,
	MutationUpdateArticleType,
} from 'src/lib/gql/mutations';
import { QueryGetArticleType } from 'src/lib/gql/queries';

const EditArticleCategory = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetArticleType, CmsGetArticleTypeVariables>(
		QueryGetArticleType,
		{
			variables: {
				id: params.id ?? '',
			},
		},
	);

	const [UpdateCategory] = useMutation<
		CmsUpdateArticleType,
		CmsUpdateArticleTypeVariables
	>(MutationUpdateArticleType, {
		onError(error) {
			CustomNotification({
				pageName: 'News Categories',
				pagePrefix: 'News Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data: CategoryForm) => {
			const rsp = await UpdateCategory({
				variables: {
					id: params.id || '',
					data: {
						name: data.name,
						weight: data.weight ? parseInt(data.weight.toString()) : null,
						slug: data.slug,
					},
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News Categories',
					pagePrefix: 'News Category',
					notificationType: 'updated',
				});

				// refetchQuery();
			}
		},
		[qryData?.getArticleType?.id],
	);

	const [DeleteCategory] = useMutation<
		CmsDeleteArticleType,
		CmsDeleteArticleTypeVariables
	>(MutationDeleteArticleType, {
		onError(error) {
			CustomNotification({
				pageName: 'News Categories',
				pagePrefix: 'News Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteType = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteCategory({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News Categories',
					pagePrefix: 'News Category',
					notificationType: 'deleted',
				});
				navigate(`${ROUTES.NEWS_CATEGORIES.path}`);
			}
		},
		[qryData?.getArticleType],
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit News Category</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				{loading ? (
					<Skeleton active />
				) : (
					<VideoCategoryCard
						categoryName={qryData?.getArticleType?.name || ''}
						categorySlug={qryData?.getArticleType?.slug || ''}
						categoryWeight={qryData?.getArticleType?.weight || 1}
						onSave={updateQry}
						isNewsCategory={true}
						canDelete={true}
						onDelete={() => {
							deleteType(params.id ?? '');
						}}
					/>
				)}
			</Row>
		</Row>
	);
};

export default EditArticleCategory;
