import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
	StarFilled,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import LiveView, { LiveViewContentType } from 'src/components/live-view';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteArticle,
	CmsDeleteArticleVariables,
} from 'src/lib/gql/generated/CmsDeleteArticle';
import { CmsGetAllArticleTypes } from 'src/lib/gql/generated/CmsGetAllArticleTypes';
import {
	CmsGetPaginatedArticles,
	CmsGetPaginatedArticlesVariables,
} from 'src/lib/gql/generated/CmsGetPaginatedArticles';
import {
	CmsUpdateArticle,
	CmsUpdateArticleVariables,
} from 'src/lib/gql/generated/CmsUpdateArticle';
import {
	MutationDeleteArticle,
	MutationUpdateArticle,
} from 'src/lib/gql/mutations';
import {
	QueryGetArticleTypes,
	QueryPaginatedArticles,
} from 'src/lib/gql/queries';
import { ArticlesTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const News = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [articleTitle, setArticleTitle] = useState<string>('');

	// const { data: qryDatea } = useQuery<CmsGetCurrentRegion>(
	// 	QueryGetCurrentRegion,
	// );
	// console.log("------------------");

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPaginatedArticles, CmsGetPaginatedArticlesVariables>(
		QueryPaginatedArticles,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				pageNumber: pageNumber,
				perPage: perPage,
				title: articleTitle,
			},
		},
	);

	const { data: qryArticleType, loading: loadingTypes } =
		useQuery<CmsGetAllArticleTypes>(QueryGetArticleTypes);

	const [DeleteNews] = useMutation<CmsDeleteArticle, CmsDeleteArticleVariables>(
		MutationDeleteArticle,
		{
			onError(error) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const deleteArticle = useCallback(
		async (id: string) => {
			if (!confirm('Confirm delete?')) return;
			const rsp = await DeleteNews({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.pagination?.articles?.result],
	);

	const [UpdateNews] = useMutation<CmsUpdateArticle, CmsUpdateArticleVariables>(
		MutationUpdateArticle,
		{
			onError(error) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const updateArticle = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
			featured: boolean | undefined,
		) => {
			const rsp = await UpdateNews({
				variables: {
					id: id,
					enabled: enabled,
					weight: pos,
					featured: featured,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.pagination?.articles?.result],
	);

	const p_Articles: ArticlesTable[] = [];

	const p_Articles_Query = qryData?.pagination?.articles?.result?.map(
		(article, i) => {
			return p_Articles.push({
				id: article?.id || '-1',
				key: (i + 1).toString() || '',
				date: format(parseISO(article?.date), 'LLL dd-yyyy'),
				tags: [],
				name: article?.title || '',
				weight: article?.weight || null,
				enabled: article.enabled || false,
				featured: article.featured || false,
				type: article.articleType?.name || '',
				counters: article.counters || [],
				slug: article.slug || '',
			});
		},
	);

	// Fields
	const columns = [
		{
			title: '#',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: string) => {
				return `${perPage * (pageNumber - 1) + parseInt(key)}`;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => {
				return a.weight - b.weight;
			},

			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateArticle(id, undefined, weight, undefined);
						}}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			ellipsis: true,
			...tableColumnTextFilterConfig<ArticlesTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: 100,
			align: 'center',

			sorter: (a, b) => {
				return new Date(a.date).valueOf() - new Date(b.date).valueOf();
			},
			render: (date: string) => date,
		},
		{
			title: 'Category',
			dataIndex: 'type',
			key: 'type',
			width: 200,
			align: 'center',
			// filters:
			// 	qryArticleType?.getArticleTypes
			// 		?.map((ed) => {
			// 			return {
			// 				text: ed?.name,
			// 				value: ed?.slug,
			// 			};
			// 		})
			// 		.filter(
			// 			(ed, index, self) =>
			// 				index ===
			// 				self.findIndex((o) => o.text === ed.text && o.value === ed.value),
			// 		) || [],
			// onFilter: (value, record) => {
			// 	return record.type.includes(value);
			// },

			// ...tableColumnTextFilterConfig<ArticlesTable>(),
			// onFilter: (value, record) => {
			// 	return record.type
			// 		.toString()
			// 		.toLowerCase()
			// 		.includes(value.toString().toLowerCase());
			// },
		},
		// {
		// 	title: 'Appears in Pages',
		// 	key: 'tags',
		// 	dataIndex: 'tags',
		// 	width: 150,
		// 	filters: tagFilter.filter(
		// 		(slider, index, self) =>
		// 			index ===
		// 			self.findIndex(
		// 				(s) => s.text === slider.text && s.value === slider.value,
		// 			),
		// 	),
		// 	onFilter: (value, record) => {
		// 		return record.tags.includes(value);
		// 	},

		// 	render: (tags: string[]) => (
		// 		<div
		// 			style={{
		// 				maxHeight: '100px',
		// 				overflowY: 'scroll',
		// 			}}
		// 		>
		// 			{tags?.map((tag) => {
		// 				const color = tag.length > 5 ? 'geekblue' : 'green';

		// 				return (
		// 					<Tag
		// 						color={color}
		// 						key={tag}
		// 						style={{
		// 							margin: '2px',
		// 							display: 'block',
		// 						}}
		// 					>
		// 						{tag.toUpperCase()}
		// 					</Tag>
		// 				);
		// 			})}
		// 		</div>
		// 	),
		// },

		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
				{
					text: 'Featured',
					value: 'Featured',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				if (value === 'Featured') return record.featured;
				else return record.enabled === value;
			},
			render: (props: {
				enabled: boolean;
				id: string;
				slug: string;
				featured: boolean;
			}) => {
				const { enabled, id, slug, featured } = props;

				return (
					<Space>
						<LiveView type={LiveViewContentType.NEWS} url={slug} />
						<StarFilled
							onClick={() => {
								updateArticle(id, undefined, undefined, !featured);
							}}
							style={{
								color: featured ? 'var(--highlighted)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
							title="Featured?"
						/>
						<Link to={`${ROUTES.EDIT_NEWS.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateArticle(id, !enabled, undefined, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteArticle(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">News / Articles</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_NEWS.path}>
						<Button className="btn-main">
							Create New Article
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<ArticlesTable>
						data={p_Articles}
						columnData={columns}
						other={{
							scroll: {
								x: 500,
							},
						}}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
							setArticleTitle(filters?.name?.toString().toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.articles?.paginationInfo?.totalResult || 0,
						}}
						expandable={{
							expandedRowRender: (record: ArticlesTable) => {
								return (
									<>
										<Row
											style={{
												margin: '1rem 0 0.8rem 0',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Text
												style={{
													textTransform: 'uppercase',
													border: '1px solid #85a6cc',
													borderRadius: '2px',
													background: 'rgb(133, 166, 204, 0.15)',
													padding: '0.35rem 1.25rem 0.25rem 1.25rem',
													fontSize: '0.7rem',
													letterSpacing: '0.05em',
													color: '#0560c7',
												}}
											>
												Page Statistics
											</Text>
										</Row>
										{record?.counters?.map((count) => {
											return (
												<React.Fragment key={`eventP${count.id}`}>
													<Row
														style={{
															margin: '1rem 0 0.8rem 0',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														{count.aspect}: {count.value}
													</Row>
												</React.Fragment>
											);
										})}
									</>
								);
							},
							rowExpandable: (record: ArticlesTable) => {
								return record?.counters?.length !== 0;
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default News;
