import { useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Radio, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { parseISO } from 'date-fns';
import React, { useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CustomNotification } from 'src/components/custom-notification';
import DatePicker from 'src/components/ui/date-picker';

import { fromUnixTime, getUnixTime } from 'date-fns';
import EditorComp from 'src/components/ui/editor';
import { NewsForm } from 'src/lib/form-interfaces';
import { CmsGetArticleTypes } from 'src/lib/gql/generated/CmsGetArticleTypes';
import { QueryArticleTypes } from 'src/lib/gql/queries';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import MediaUpload from '../media-upload';
import SelectTags from '../select-tags';
import SlugInput from '../slug-input';
import JumpTo from '../jump-to';
import { ArticleJumpToInput } from 'src/lib/gql/generated/globalTypes';

interface Props {
	newsMedia?: Media | undefined;
	newsName?: string; // Story Name
	newsSlug?: string; //Optional - Link for story
	newsPosition?: number | undefined; //Position of Story
	newsDate?: string; //Selected Dates
	scrapDate?: string; //Selected Dates
	newsContent?: string;
	newsType?: string;
	newsTags?: string[];
	newsMeta?: string;
	canDelete?: boolean;
	featured?: boolean;
	enabled?: boolean;
	publishTime?: string;
	jumpTo?: ArticleJumpToInput[] | null;
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: (data: NewsForm) => void;
}
const NewsCard = (props: Props) => {
	const mediaId = useRef<Media | undefined>(props.newsMedia);
	const [selectedDay, setSelectedDay] = useState<Date | null>(
		props.newsDate ? new Date(props.newsDate) : null,
	);

	const [jumpTo, setJumpTo] = useState<ArticleJumpToInput[]>(
		props?.jumpTo || [],
	);

	const { data: qryData } = useQuery<CmsGetArticleTypes>(QueryArticleTypes, {
		fetchPolicy: 'cache-and-network',
	});

	const selectedHTags = useRef<string[]>([]);

	const [form] = useForm<NewsForm>();

	const { Option } = Select;

	function handleDayClick(day: Date) {
		setSelectedDay(day);
	}

	const createNews = async (data: NewsForm) => {
		if (mediaId.current === undefined) {
			CustomNotification({
				pageName: 'News/Articles',
				errorName: 'Cover Image',
				notificationType: 'error',
			});
			return;
		}

		if (selectedDay === null || selectedDay === undefined) {
			CustomNotification({
				pageName: 'News/Articles',
				errorName: 'Date',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			title: data.title,
			content: data.content,
			date: selectedDay,
			description: data.description,
			mediaId: mediaId.current?.id || '',
			meta: data.meta,
			slug: data.slug,
			articleTypeId: data.articleTypeId,
			weight: data.weight,
			tags: selectedHTags.current,
			scrapDate: data.scrapDate,
			featured: data.featured,
			enabled: data.publishTime ? false : true,
			publishTime: data.publishTime ? getUnixTime(data.publishTime) : undefined,
			jumpTo,
		});
	};

	return (
		<HelmetProvider>
			<Form form={form} onFinish={createNews}>
				<Helmet>
					<script async defer src="//www.instagram.com/embed.js"></script>
				</Helmet>
				<Row style={{ width: '100%', marginTop: '1em' }} gutter={16}>
					<Col span={24}>
						<MediaUpload
							folder={MediaFoldersNames.article}
							onMediaUploaded={(media: Media) => {
								mediaId.current = media;
							}}
							media={mediaId.current}
							accept="IMAGE"
							placeholderText={'Drag and drop a news cover photo'}
						/>
					</Col>

					{/* Name / Title */}
					<Col span={24} lg={18}>
						<label className="input-fields-label">Name / Title</label>

						<Form.Item name="title" initialValue={props.newsName}>
							<Input
								type="text"
								placeholder="Name / Title"
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					{/* Position */}
					<Col span={24} lg={6}>
						<label className="input-fields-label">Position</label>

						<Form.Item
							name="weight"
							initialValue={props.newsPosition}
							rules={[
								{
									validator: (rule, value: string, back) => {
										if (value !== undefined) {
											if (value.length > 4) {
												back('Too large');
												return;
											}
											if (value !== '' && parseInt(value) < 1) {
												back('Minimum value is 1');
												return;
											}
										}
										back(undefined);
										return;
									},
								},
							]}
						>
							<Input
								type="number"
								min={1}
								placeholder="Position / Sequence No."
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<label className="input-fields-label">SEO Meta</label>

						<Form.Item
							rules={[{ required: true, min: 50, max: 200 }]}
							name="meta"
							initialValue={props.newsMeta}
						>
							<Input
								type="text"
								placeholder="SEO Meta"
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					<Col span={24} lg={12}>
						<label className="input-fields-label">Slug</label>

						<Form.Item
							name="slug"
							rules={[{ required: true }]}
							initialValue={props.newsSlug}
							hasFeedback
						>
							<SlugInput />
						</Form.Item>
					</Col>

					<Col span={24} lg={12}>
						<label className="input-fields-label">Page Tags</label>

						<SelectTags
							initialValues={props.newsTags || []}
							onSelectionChange={(tags) => {
								selectedHTags.current = tags;
							}}
							onInit={(tags) => (selectedHTags.current = tags)}
						/>
					</Col>

					<JumpTo jumpTo={jumpTo} setJumpTo={setJumpTo} />

					{/* Content Editor */}
					<Col span={24}>
						<Row className="input-fields">
							<label className="input-fields-label">
								Article / News Content
							</label>

							<Form.Item
								name="content"
								initialValue={props.newsContent}
								style={{
									width: '100%',
								}}
								rules={[{ required: true }]}
							>
								<EditorComp
									value={props.newsContent || '<p>News Content</p>'}
								/>
							</Form.Item>
						</Row>
					</Col>

					<Col span={24} lg={16}>
						<label className="input-fields-label">Article Type</label>

						<Form.Item
							name="articleTypeId"
							rules={[{ required: true }]}
							initialValue={props.newsType}
							hasFeedback
						>
							<Select
								suffixIcon={null}
								style={{ width: '100%' }}
								placeholder="Article Type"
								size="middle"
								className="bg-select"
							>
								{qryData?.getArticleTypes?.map((articleType) => {
									return (
										<Option key={articleType?.id} value={articleType?.id || ''}>
											{articleType?.name}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<div>
							<Text
								style={{
									display: 'block',
								}}
							>
								Featured
							</Text>
							<Text
								style={{
									display: 'block',
									fontSize: '0.8em',
									opacity: '0.50',
									marginBottom: '1em',
								}}
							>
								Set article to be featured and shown on every webpage that has
								featured articles.
							</Text>
						</div>

						<Form.Item
							name="featured"
							initialValue={props.featured || false}
							rules={[{ required: true }]}
						>
							<Radio.Group>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>

						<div>
							<Text
								style={{
									display: 'block',
								}}
							>
								Scrap Date
							</Text>
							<Text
								style={{
									display: 'block',
									fontSize: '0.8em',
									opacity: '0.50',
									marginBottom: '1em',
								}}
							>
								Remove the article from showing in the main pages but keep it in
								the archive after the inserted scrap date
							</Text>
						</div>

						<Form.Item
							name="scrapDate"
							initialValue={
								props.scrapDate ? parseISO(props.scrapDate) : undefined
							}
							style={{
								width: '100%',
							}}
						>
							<DatePicker
								placeholder="Scrap Date"
								style={{
									width: 'clamp(10rem, 60% ,100%)',
								}}
							/>
						</Form.Item>
						{!props.enabled && (
							<>
								<div>
									<Text
										style={{
											display: 'block',
										}}
									>
										Scheduled Publish
									</Text>
									<Text
										style={{
											display: 'block',
											fontSize: '0.8em',
											opacity: '0.50',
											marginBottom: '1em',
										}}
									>
										Enter a date and hour to schedule the article to be
										published. Leave empty to publish immediately
									</Text>
								</div>

								<Form.Item
									name="publishTime"
									initialValue={
										props.publishTime
											? fromUnixTime(Number.parseInt(props.publishTime))
											: undefined
									}
									style={{
										width: '100%',
									}}
								>
									<DatePicker
										showNow={false}
										showTime={{ format: 'HH' }}
										format="YYYY-MM-DD HH:mm"
										placeholder="Publish Date and Hour"
										style={{
											width: 'clamp(10rem, 60% ,100%)',
										}}
									/>
								</Form.Item>
							</>
						)}
					</Col>

					<Col span={24} lg={8}>
						<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
							Published Date
						</Text>
						<Text
							style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}
						>
							Click on a date to provide the publish date of the article.
						</Text>

						<DayPicker
							onDayClick={handleDayClick}
							selectedDays={selectedDay || new Date()}
							showOutsideDays={true}
							className="margin-0"
							initialMonth={selectedDay || new Date()}
						/>
					</Col>

					<Col span={24}>
						<hr style={{ opacity: '0.25' }} />

						<Row justify={'center'}>
							<Space size="small">
								<Button className="btn-main save" htmlType={'submit'}>
									Save
								</Button>

								{props.canDelete ? (
									<Button className="btn-main delete" onClick={props.onDelete}>
										Delete
									</Button>
								) : (
									<Button
										className="btn-main"
										onClick={() => {
											window.history.back();
										}}
									>
										Cancel
									</Button>
								)}
							</Space>
						</Row>
					</Col>
				</Row>
			</Form>
		</HelmetProvider>
	);
};

export default NewsCard;
