import { InboxOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import React, { useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { uploadFile } from 'src/lib/axios';
import {
	CmsCreateEventPhoto,
	CmsCreateEventPhotoVariables,
} from 'src/lib/gql/generated/CmsCreateEventPhoto';
import { MutationCreateEventPhoto } from 'src/lib/gql/mutations';
import { MediaFoldersNames } from 'src/lib/interface';

interface Props {
	eventDateId: string;
	reFetch: () => void;
	onChange?: () => void;
}

const sleep = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

interface FileList {
	[index: string]: UploadFile;
}
const PhotographerUpload = (props: Props) => {
	const { Dragger } = Upload;

	const [, setReRender] = useState<number>(0);

	const [mutationCreateEventPhoto] = useMutation<
		CmsCreateEventPhoto,
		CmsCreateEventPhotoVariables
	>(MutationCreateEventPhoto);

	const fileList = useRef<FileList>({});
	const queueList = useRef<string[]>([]);
	const queueOrder = useRef<string[]>([]);
	const lastCalled = useRef<number>(-1);
	const uploadProps: DraggerProps = {
		fileList: Object.keys(fileList.current).map((key) => fileList.current[key]),
		name: 'file',
		accept: 'image/*',
		multiple: true,
		onRemove: (nFile) => {
			delete fileList.current[nFile.uid];
			setReRender(Math.random());
		},
		customRequest: (e) => {
			const nFile: RcFile = e.file as RcFile;
			fileList.current[nFile.uid] = {
				...nFile,
				status: 'done',
				thumbUrl: URL.createObjectURL(e.file as RcFile),
				percent: 0,
			};
			queueOrder.current.push(nFile.uid);
			setReRender(Math.random());
			new Promise(async (resolve, reject) => {
				while (
					queueList.current.length > 10 ||
					queueOrder.current[lastCalled.current + 1] !== nFile.uid
				) {
					await sleep(1000);
				}
				queueList.current.push(nFile.uid);
				lastCalled.current++;
				fileList.current[nFile.uid].status = 'uploading';
				setReRender(Math.random());
				uploadFile({
					file: nFile,
					folder: MediaFoldersNames.event,
					name: undefined,
					watermark: true,
					onProgress: (progress) => {
						const percent =
							((progress?.loaded || 1) / (progress?.total || 1)) * 100;
						if (percent - (fileList.current[nFile.uid].percent || 0) > 20) {
							fileList.current[nFile.uid].percent = percent;
							setReRender(Math.random());
						}
					},
				})
					.then(async (rsp) => {
						if (rsp.data.data) {
							await mutationCreateEventPhoto({
								variables: {
									data: {
										eventDateId: props.eventDateId,
										mediaId: [rsp.data.data.id]
											.map((value) => ({ value, sort: Math.random() }))
											.sort((a, b) => a.sort - b.sort)
											.map(({ value }) => value),
									},
								},
							});
							delete fileList.current[nFile.uid];
							queueList.current = queueList.current.filter(
								(e) => e !== nFile.uid,
							);
							setReRender(Math.random());
						} else {
							CustomNotification({
								pageName: 'Photo Upload',
								pagePrefix: 'Upload',
								notificationType: 'custom-error',
								customDescription: `${nFile.name} failed`,
							});
							fileList.current[nFile.uid] = {
								...fileList.current[nFile.uid],
								status: 'error',
							};
							queueList.current = queueList.current.filter(
								(e) => e !== nFile.uid,
							);
							setReRender(Math.random());
						}
						resolve(0);
					})
					.catch((e) => {
						CustomNotification({
							pageName: 'Photo Upload',
							pagePrefix: 'Upload',
							notificationType: 'custom-error',
							customDescription: `${nFile.name} failed \n ${e.message}`,
						});
						fileList.current[nFile.uid] = {
							...fileList.current[nFile.uid],
							status: 'error',
						};
						queueList.current = queueList.current.filter(
							(e) => e !== nFile.uid,
						);
						setReRender(Math.random());
						resolve(0);
					});
			});
		},
		showUploadList: true,
		listType: 'picture-card',
		type: 'drag',
	};
	return (
		<Dragger {...uploadProps} onChange={props.onChange}>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-text">
				Click or drag file to this area to upload
			</p>
			<p className="ant-upload-hint">You can drag and drop to upload a bulk</p>
		</Dragger>
	);
};

export default PhotographerUpload;
