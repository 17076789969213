import {
	CloseCircleFilled,
	EditOutlined,
	// DragOutlined,
} from '@ant-design/icons';
/*
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	closestCorners,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	SortableContext,
	arrayMove,
	rectSortingStrategy,
	sortableKeyboardCoordinates,
	useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
*/
import { Button, Col, Input } from 'antd';
import { useEffect, useState } from 'react';
import { ArticleJumpToInput } from 'src/lib/gql/generated/globalTypes';

interface Props {
	jumpTo: ArticleJumpToInput[];
	setJumpTo: (
		prevState:
			| ArticleJumpToInput[]
			| ((prevState: ArticleJumpToInput[]) => ArticleJumpToInput[]),
	) => void;
}

const findjumpToLink = (jumpToLinks: ArticleJumpToInput[], jumpToLink) =>
	jumpToLinks.find((item) => item.jumpToLink === jumpToLink);

const LinItem = ({ item, deletejumpToLink, setJumpTo }: any) => {
	/*
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({
			id: item.jumpToLink,
			transition: {
				duration: 150,
				easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
			},
		});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	*/

	const [isEditMode, setIsEditMode] = useState(false);
	const [values, setValues] = useState({
		jumpToLink: item.jumpToLink,
		jumpToText: item.jumpToText,
	});

	useEffect(() => {
		if (!isEditMode) {
			setJumpTo((prev) =>
				prev.map((el) => {
					if (el.jumpToLink !== item.jumpToLink) return el;

					return values;
				}),
			);
		}
	}, [isEditMode]);

	return (
		<div
			/*
			ref={setNodeRef}
			style={style}
			*/
			className="jump-to-item"
			key={item.jumpToLink}
		>
			{/* <Button {...attributes} {...listeners} icon={<DragOutlined />} /> */}
			<p>
				Text:{' '}
				{isEditMode ? (
					<Input
						style={{ width: 'auto' }}
						onChange={(e) =>
							setValues({
								...values,
								jumpToText: e.target.value,
							})
						}
						value={values.jumpToText}
					/>
				) : (
					item.jumpToText
				)}
				(Linked to:{' '}
				{isEditMode ? (
					<Input
						style={{ width: 'auto' }}
						onChange={(e) =>
							setValues({
								...values,
								jumpToLink: e.target.value,
							})
						}
						value={values.jumpToLink}
					/>
				) : (
					item.jumpToLink
				)}
				)
			</p>
			<div>
				<Button onClick={() => setIsEditMode(!isEditMode)}>
					<EditOutlined />
				</Button>
				<Button onClick={() => deletejumpToLink(item.jumpToLink)}>
					<CloseCircleFilled />
				</Button>
			</div>
		</div>
	);
};

const JumpTo = ({ jumpTo, setJumpTo }: Props) => {
	/*
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);
	*/
	const [currentValue, setCurrentValue] = useState({
		jumpToText: '',
		jumpToLink: '#',
	});

	const isBtnDisabled =
		!currentValue.jumpToText || currentValue.jumpToLink.length < 2;

	const handleChangejumpToLink = (event) => {
		let newValue = event.target.value;
		if (!newValue.startsWith('#')) {
			newValue = '#' + newValue;
		}
		setCurrentValue({
			...currentValue,
			jumpToLink: newValue,
		});
	};

	const addjumpToLink = () => {
		const { jumpToLink, jumpToText } = currentValue;
		if (findjumpToLink(jumpTo, jumpToLink)) return;

		setJumpTo([
			...jumpTo,
			{
				jumpToLink,
				jumpToText,
			},
		]);
		setCurrentValue({
			jumpToText: '',
			jumpToLink: '#',
		});
	};

	const deletejumpToLink = (jumpToLink: string) => {
		setJumpTo((prev) => {
			return prev.filter((item) => item.jumpToLink !== jumpToLink);
		});
	};

	/*
	const handleDragEnd = ({ active, over }) => {
		if (over && active.id !== over?.id) {
			const activeIndex = jumpTo.findIndex(
				({ jumpToLink }) => jumpToLink === active.id,
			);
			const overIndex = jumpTo.findIndex(
				({ jumpToLink }) => jumpToLink === over.id,
			);

			setJumpTo(arrayMove(jumpTo, activeIndex, overIndex));
		}
	};
	*/

	return (
		<>
			<Col span={24}>
				<label className="input-fields-label">Jump to</label>
			</Col>
			<Col span={24} lg={12}>
				<Input
					onChange={(e) =>
						setCurrentValue({
							...currentValue,
							jumpToText: e.target.value,
						})
					}
					value={currentValue.jumpToText}
					type="text"
					placeholder="Text"
					className="input-fields"
				/>
			</Col>
			<Col span={24} lg={12}>
				<Input
					onChange={handleChangejumpToLink}
					value={currentValue.jumpToLink}
					type="text"
					placeholder="jumpToLink"
					className="input-fields"
				/>
			</Col>
			<Col span={24}>
				<Button
					disabled={isBtnDisabled}
					onClick={addjumpToLink}
					className="btn-main add-jumpToLink-btn"
				>
					Add jumpToLink
				</Button>
				<br />
			</Col>
			<Col span={24}>
				{/* <DndContext
					sensors={sensors}
					collisionDetection={closestCorners}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={jumpTo.map((item) => item.jumpToLink)}
						strategy={rectSortingStrategy}
					> */}
				{jumpTo.map((item) => (
					<LinItem
						key={item.jumpToLink}
						item={item}
						setJumpTo={setJumpTo}
						deletejumpToLink={deletejumpToLink}
					/>
				))}
				{/* </SortableContext>
				</DndContext> */}
			</Col>
		</>
	);
};

export default JumpTo;
