import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import ThingsToDoCard from 'src/components/ui/things-to-do/things-to-do-card';
import { ROUTES } from 'src/config/route';
import {
	CmsDeleteThingsToDo,
	CmsDeleteThingsToDoVariables,
} from 'src/lib/gql/generated/CmsDeleteThingsToDo';
import { CmsGetEventType } from 'src/lib/gql/generated/CmsGetEventType';
import {
	CmsUpdateThingsToDo,
	CmsUpdateThingsToDoVariables,
} from 'src/lib/gql/generated/CmsUpdateThingsToDo';
import { MediaType } from 'src/lib/gql/generated/globalTypes';
import {
	MutationDeleteThingsToDo,
	MutationUpdateThingsToDo,
} from 'src/lib/gql/mutations';
import { GetThingsToDo } from 'src/lib/gql/queries';

interface Params {
	id: string;
}

const EditThingsToDo = () => {
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetEventType>(GetThingsToDo, {
		variables: {
			id: params.id,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [UpdateThingsToDo] = useMutation<
		CmsUpdateThingsToDo,
		CmsUpdateThingsToDoVariables
	>(MutationUpdateThingsToDo, {
		onError(error) {
			CustomNotification({
				pageName: 'Things to Do',
				pagePrefix: 'Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data) => {
			const rsp = await UpdateThingsToDo({
				variables: {
					id: qryData?.getEventType?.id || '-1',
					hTag: data.htag,
					coverImageId: data.coverImage,
					slug: data.slug,
					weight: data.weight,
					name: data.name,
					color: data.color,
					metaDescription: data.metaDescription,
					metaTitle: data.metaTitle,
					metaKeywords: data.metaKeywords,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Things to Do',
					pagePrefix: 'Category',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getEventType?.id],
	);

	const cMeadia = useMemo(() => {
		return qryData?.getEventType?.coverImage
			? {
					id: qryData?.getEventType?.coverImage?.id,
					url: qryData?.getEventType.coverImage?.url || '',
					type: MediaType.IMAGE,
			  }
			: undefined;
	}, [qryData?.getEventType?.coverImage?.id]);

	const [deleteThingsToDo] = useMutation<
		CmsDeleteThingsToDo,
		CmsDeleteThingsToDoVariables
	>(MutationDeleteThingsToDo, {
		onError(error) {
			CustomNotification({
				pageName: 'Things to Do',
				pagePrefix: 'Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Category</Text>
			</Col>

			{qryLoading ? (
				<Skeleton active />
			) : (
				<ThingsToDoCard
					onSave={updateQry}
					categoryName={qryData?.getEventType?.name || ''}
					categorySlug={qryData?.getEventType?.slug || ''}
					categoryTag={qryData?.getEventType?.hTag || ''}
					categoryMedia={cMeadia}
					categoryEventColor={qryData?.getEventType?.color || ''}
					categoryPosition={qryData?.getEventType?.weight || 0}
					categoryMetaDescription={qryData?.getEventType?.metaDescription || ''}
					categoryMetaTitle={qryData?.getEventType?.metaTitle || ''}
					categoryMetaKeywords={qryData?.getEventType?.metaKeywords || ''}
					canDelete={true}
					onDelete={async () => {
						if (!confirm('Are you sure you want to delete?')) return;

						const rsp = await deleteThingsToDo({
							variables: {
								id: params.id || '',
							},
						});

						if (rsp.data) {
							CustomNotification({
								pageName: 'Things to Do',
								pagePrefix: 'Category',
								notificationType: 'deleted',
							});

							navigate(`${ROUTES.THINGS_TO_DO.path}`);
						}
					}}
				/>
			)}
		</Row>
	);
};

export default EditThingsToDo;
